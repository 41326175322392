// third-party
import React from "react";
import Links_Icon from "../assets/navigation/Link_Icon";
import BulkLinks_Icon from "../assets/navigation/BulkLinks_Icon";
import LinkBio_Icon from "../assets/navigation/LinkBio_Icon";
import Spaces_Icon from "../assets/navigation/Spaces_Icon";
import Domains_Icon from "../assets/navigation/Domains_Icon";
import DeepLinking from "../assets/navigation/DeepLinking";
import Custom_QR_Code from "../assets/navigation/Custom_QR_Code";
import FormattedMessageCustomize from "./FormattedMessageCusttomize";
import DownloadFiles_Icon from "../assets/navigation/DownloadFiles_Icon";
import ImportLink_Icon from "../assets/navigation/ImportLink_Icon";
import LinkCloaking_Icon from "../assets/navigation/LinkCloaking_Icon";
import FavouritesLinks_Icon from "../assets/navigation/FavouritesLinks_Icon";

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const getLinksGroup = ({
  spaceDetailsTitle = "Space Details",
  bulkLinkDetailsTitle = "Bulk-Link Details",
}) => {
  return {
    id: "links_group",
    title: <FormattedMessageCustomize id="LinksGroup" />,
    icon: Links_Icon,
    type: "group",
    children: [
      {
        id: "Links",
        title: <FormattedMessageCustomize id="Links" />,
        type: "item",
        url: "/links",
        icon: Links_Icon,
        index: 2,
      },
      {
        id: "Deep Linking",
        title: <FormattedMessageCustomize id="DeepLinking" />,
        type: "item",
        url: "/deepLinking",
        icon: DeepLinking,
        index: 3,
      },
      {
        id: "Add New Deep-Linking",
        title: <FormattedMessageCustomize id="AddNewDeepLinking" />,
        type: "subitem",
        url: "/deepLinking/addNewDeepLinking",
        icon: DeepLinking,
        parents: {
          id: "Deep Linking",
          title: <FormattedMessageCustomize id="DeepLinking" />,
          url: "/deepLinking",
        },
      },
      {
        id: "Edit Deep-Linking",
        title: <FormattedMessageCustomize id="Edit Deep-Linking" />,
        type: "subitem",
        url: "/deepLinking/editDeepLinking",
        icon: DeepLinking,
        parents: {
          id: "Deep Linking",
          title: <FormattedMessageCustomize id="DeepLinking" />,
          url: "/deepLinking",
        },
      },
      {
        id: "State Deep-Linking",
        title: <FormattedMessageCustomize id="Stats" />,
        type: "subitem",
        url: "/deepLinking/stats",
        icon: DeepLinking,
        parents: {
          id: "Deep Linking",
          title: <FormattedMessageCustomize id="DeepLinking" />,
          url: "/deepLinking",
        },
      },
      {
        id: "Link Cloaking",
        title: <FormattedMessageCustomize id="LinkCloaking" />,
        type: "item",
        url: "/linkCloaking",
        icon: LinkCloaking_Icon,
        index: 4,
      },
      {
        id: "Add New Link Cloaking",
        title: <FormattedMessageCustomize id="AddNewLinkCloaking" />,
        type: "subitem",
        url: "/linkCloaking/addNewLinkCloaking",
        icon: LinkCloaking_Icon,
        parents: {
          id: "Link Cloaking",
          title: <FormattedMessageCustomize id="LinkCloaking" />,
          url: "/linkCloaking",
        },
      },
      {
        id: "Edit Link Cloaking",
        title: <FormattedMessageCustomize id="EditLinkCloaking" />,
        type: "subitem",
        url: "/linkCloaking/editLinkCloaking",
        icon: LinkCloaking_Icon,
        parents: {
          id: "Link Cloaking",
          title: <FormattedMessageCustomize id="LinkCloaking" />,
          url: "/linkCloaking",
        },
      },
      {
        id: "State Link Cloaking",
        title: <FormattedMessageCustomize id="EditLinkCloaking" />,
        type: "subitem",
        url: "/linkCloaking/State",
        icon: LinkCloaking_Icon,
        parents: {
          id: "Link Cloaking",
          title: <FormattedMessageCustomize id="LinkCloaking" />,
          url: "/linkCloaking",
        },
      },
      {
        id: "Bulk Links",
        title: <FormattedMessageCustomize id="BulkLinks" />,
        type: "item",
        url: "/bulkLinks",
        icon: BulkLinks_Icon,
        index: 5,
      },
      {
        id: "Advance",
        title: <FormattedMessageCustomize id="ShortLinkAdvance" />,
        type: "subitem",
        url: "/links/advance-link",
        icon: Links_Icon,
        parents: {
          id: "Links",
          title: <FormattedMessageCustomize id="Links" />,
          url: "/links",
        },
      },
      {
        id: bulkLinkDetailsTitle,
        title: <FormattedMessageCustomize id={bulkLinkDetailsTitle} />,
        type: "subitem",
        url: "/bulkLinks/bulkDetails",
        icon: BulkLinks_Icon,
        parents: {
          id: "Bulk Links",
          title: <FormattedMessageCustomize id="BulkLinks" />,
          url: "/bulkLinks",
        },
      },
      {
        id: "Stats",
        title: <FormattedMessageCustomize id="Stats" />,
        type: "subitem",
        url: "/bulkLinks/analytics",
        icon: Links_Icon,
        parents: {
          id: "Bulk Links",
          title: <FormattedMessageCustomize id="BulkLinks" />,
          url: "/bulkLinks",
        },
      },
      {
        id: "Link Stats",
        title: <FormattedMessageCustomize id="LinkStats" />,
        type: "subitem",
        url: "/bulkLinks/bulk-stat",
        icon: BulkLinks_Icon,
        parents: {
          id: "Bulk Links",
          title: <FormattedMessageCustomize id="BulkLinks" />,
          url: "/bulkLinks",
        },
      },
      {
        id: "Edit Bulk Links",
        title: <FormattedMessageCustomize id="EditBulkLinks" />,
        type: "subitem",
        url: "/bulkLinks/bulkEdit",
        icon: BulkLinks_Icon,
        parents: {
          id: "Bulk Links",
          title: <FormattedMessageCustomize id="BulkLinks" />,
          url: "/bulkLinks",
        },
      },
      {
        id: "Link-Bio",
        title: <FormattedMessageCustomize id="Link-Bio" />,
        type: "item",
        url: "/linkBio",
        icon: LinkBio_Icon,
        index: 6,
      },
      {
        id: "Link-Bio stats",
        title: <FormattedMessageCustomize id="LinkBioStats" />,
        type: "subitem",
        url: "/linkBio/bioLinkDetails",
        icon: LinkBio_Icon,
        parents: {
          id: "Link-Bio",
          title: <FormattedMessageCustomize id="Link-Bio" />,
          url: "/linkBio",
        },
      },
      {
        id: "Link-Bio Link Stats",
        title: <FormattedMessageCustomize id="LinkBioLinkStats" />,
        type: "subitem",
        url: "/linkBio/bioLinkStats",
        icon: LinkBio_Icon,
        parents: {
          id: "Link-Bio",
          title: <FormattedMessageCustomize id="Link-Bio" />,
          url: "/linkBio",
        },
      },
      {
        id: "Link-Bio Icon Stats",
        title: <FormattedMessageCustomize id="LinkBioIconStats" />,
        type: "subitem",
        url: "/linkBio/bioLinkIconStats",
        icon: LinkBio_Icon,
        parents: {
          id: "Link-Bio",
          title: <FormattedMessageCustomize id="Link-Bio" />,
          url: "/linkBio",
        },
      },
      {
        id: spaceDetailsTitle,
        title: <FormattedMessageCustomize id={spaceDetailsTitle} />,
        type: "subitem",
        url: "/spaces/detail",
        icon: Spaces_Icon,
        parents: {
          id: "Spaces",
          title: <FormattedMessageCustomize id="Spaces" />,
          url: "/spaces",
        },
      },
      {
        id: "Spaces",
        title: <FormattedMessageCustomize id="Spaces" />,
        type: "item",
        url: "/spaces",
        icon: Spaces_Icon,
        index: 7,
      },
      {
        id: "Domains",
        title: <FormattedMessageCustomize id="Domains" />,
        type: "item",
        url: "/domains",
        icon: Domains_Icon,
        index: 8,
      },
      {
        id: "Add Domain",
        title: <FormattedMessageCustomize id="AddDomain" />,
        type: "subitem",
        url: "/domains/add",
        icon: Domains_Icon,
        parents: {
          id: "Domains",
          title: <FormattedMessageCustomize id="Domains" />,
          url: "/domains",
        },
      },
      {
        id: "Update Domains",
        title: <FormattedMessageCustomize id="UpdateDomains" />,
        type: "subitem",
        url: "/domains/update",
        icon: Domains_Icon,
        parents: {
          id: "Domains",
          title: <FormattedMessageCustomize id="Domains" />,
          url: "/domains",
        },
      },
      {
        id: "Stats",
        title: <FormattedMessageCustomize id="Stats" />,
        type: "subitem",
        url: "/domains/stats",
        icon: Links_Icon,
        parents: {
          id: "Domains",
          title: <FormattedMessageCustomize id="Domains" />,
          url: "/domains",
        },
      },
      {
        id: "Stats",
        title: <FormattedMessageCustomize id="Stats" />,
        type: "subitem",
        url: "/links/stats",
        icon: Links_Icon,
        parents: {
          id: "Links",
          title: <FormattedMessageCustomize id="Links" />,
          url: "/links",
        },
      },
      {
        id: "Edit Links",
        title: <FormattedMessageCustomize id="EditLinks" />,
        type: "subitem",
        url: "/links/edit-link",
        icon: Links_Icon,
        parents: {
          id: "Links",
          title: <FormattedMessageCustomize id="Links" />,
          url: "/links",
        },
      },
      {
        id: "Stats",
        title: <FormattedMessageCustomize id="Stats" />,
        type: "subitem",
        url: "/spaces/analytics",
        icon: Links_Icon,
        parents: {
          id: "Spaces",
          title: <FormattedMessageCustomize id="Spaces" />,
          url: "/spaces",
        },
      },
      {
        id: "Link Stats",
        title: <FormattedMessageCustomize id="LinkStats" />,
        type: "subitem",
        url: "/spaces/stats",
        icon: Links_Icon,
        parents: {
          id: "Spaces",
          title: <FormattedMessageCustomize id="Spaces" />,
          url: "/spaces",
        },
      },
      {
        id: "Custom QR Code",
        title: <FormattedMessageCustomize id="CustomQRCode" />,
        type: "item",
        url: "/qrCode",
        icon: Custom_QR_Code,
        index: 9,
      },
      {
        id: "Add Custom QR Code",
        title: <FormattedMessageCustomize id="AddCustomQRCode" />,
        type: "subitem",
        url: "/qrCode/addQRCode",
        icon: Custom_QR_Code,
        parents: {
          id: "Custom QR Code",
          title: <FormattedMessageCustomize id="CustomQRCode" />,
          url: "/qrCode",
        },
      },
      {
        id: "Edit Custom QR Code",
        title: <FormattedMessageCustomize id="EditCustomQRCode" />,
        type: "subitem",
        url: "/qrCode/editQRCode",
        icon: Custom_QR_Code,
        parents: {
          id: "Custom QR Code",
          title: <FormattedMessageCustomize id="CustomQRCode" />,
          url: "/qrCode",
        },
      },
      {
        id: "Custom QR Code Stats",
        title: <FormattedMessageCustomize id="CustomQRCodeStats" />,
        type: "subitem",
        url: "/qrCode/QRCodeStats",
        icon: Custom_QR_Code,
        parents: {
          id: "Custom QR Code",
          title: <FormattedMessageCustomize id="CustomQRCode" />,
          url: "/qrCode",
        },
      },
      {
        id: "ShareFiles",
        title: <FormattedMessageCustomize id="ShareFiles" />,
        type: "item",
        url: "/files",
        icon: DownloadFiles_Icon,
        index: 10,
      },
      {
        id: "File Detail",
        title: <FormattedMessageCustomize id="FileDetail" />,
        type: "subitem",
        url: "/files/fileDetail",
        icon: DownloadFiles_Icon,
        parents: {
          id: "ShareFiles",
          title: <FormattedMessageCustomize id="ShareFiles" />,
          url: "/files",
        },
      },
      {
        id: "File Stats",
        title: <FormattedMessageCustomize id="FileStats" />,
        type: "subitem",
        url: "/files/fileUrlsStats",
        icon: DownloadFiles_Icon,
        parents: {
          id: "ShareFiles",
          title: <FormattedMessageCustomize id="ShareFiles" />,
          url: "/files",
        },
      },
      {
        id: "ImportLink",
        title: <FormattedMessageCustomize id="ImportLink" />,
        type: "item",
        url: "/importLink",
        icon: ImportLink_Icon,
        index: 11,
      },
      {
        id: "Import Link Detail",
        title: <FormattedMessageCustomize id="ImportLinkDetail" />,
        type: "subitem",
        url: "/importLink/importLinkDetail",
        icon: DownloadFiles_Icon,
        parents: {
          id: "ImportLink",
          title: <FormattedMessageCustomize id="ImportLink" />,
          url: "/importLink",
        },
      },
      {
        id: "Favourite Links",
        title: <FormattedMessageCustomize id="FavouriteLinks" />,
        type: "item",
        url: "/favouriteLinks",
        icon: FavouritesLinks_Icon,
        index: 12,
      },
    ],
  };
};

export default getLinksGroup;
