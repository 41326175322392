import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dateRangPickerActive from "../assets/Dashboard/dateRangPickerActiveButton.png";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "iconsax-react";
import { useTheme } from "@mui/styles";
import { ThemeMode } from "../config";
// ==============================|| DATE PICKER - DATE RANGE ||============================== //

export default function DateRangePicker({
  setSelect,
  setSelectDateRange,
  selectDateRange,
  selectsRange = true,
  disabled = false,
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, endDate] = selectsRange ? selectDateRange : [null, null];

  useEffect(() => {
    if (selectsRange) {
      if (!!startDate && !!endDate) {
        setIsOpen(!isOpen);
        setSelect("dateRange");
      }
    }
  }, [selectDateRange]);

  const handleChange = (e) => {
    setSelectDateRange(e);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      {selectsRange ? (
        <DatePicker
          onClickOutside={() => {
            if (!!startDate && !endDate) {
              setSelect("last7");
              setSelectDateRange([null, null]);
              setIsOpen(false);
            }
          }}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          style={{ zIndex: 99 }}
          maxDate={new Date()}
          customInput={
            <Button
              className="example-custom-input"
              onClick={handleClick}
              sx={{
                borderRadius: "50%",
                padding: "0",
                minWidth: "40px",
                position: "relative",
              }}
            >
              {!!startDate && !!endDate ? (
                <img width={"40px"} src={dateRangPickerActive} alt="date" />
              ) : (
                <Box
                  sx={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                    border: `1px solid ${
                      theme.palette.mode === ThemeMode.DARK
                        ? theme.palette.secondary[200]
                        : theme.palette.secondary.light
                    }`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "36px",
                  }}
                >
                  <Calendar
                    size={36}
                    color={
                      theme.palette.mode === ThemeMode.DARK ? "#fff" : "#000"
                    }
                  />
                </Box>
              )}
            </Button>
          }
          disabled={disabled}
        />
      ) : (
        <DatePicker
          selectsRange={false}
          selected={selectDateRange || new Date()}
          style={{ zIndex: 99 }}
          onChange={(date) => {
            setSelectDateRange(date);
            setIsOpen(false);
          }}
          maxDate={new Date()}
          customInput={
            <Button
              className="example-custom-input"
              onClick={handleClick}
              sx={{
                borderRadius: "50%",
                padding: "0",
                minWidth: "40px",
                position: "relative",
              }}
            >
              {!!startDate && !!endDate ? (
                <img width={"40px"} src={dateRangPickerActive} alt="date" />
              ) : (
                <Box
                  sx={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                    border: `1px solid ${
                      theme.palette.mode === ThemeMode.DARK
                        ? theme.palette.secondary[200]
                        : theme.palette.secondary.light
                    }`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "36px",
                  }}
                >
                  <Calendar
                    size={36}
                    color={
                      theme.palette.mode === ThemeMode.DARK ? "#fff" : "#000"
                    }
                  />
                </Box>
              )}
            </Button>
          }
        />
      )}
    </>
  );
}
