import React from "react";
import FoxlyLoader from "../../assets/images/Foxly_logo.png";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

const FoxlyLogoLoader = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          overflow: "hidden",
          width: "80px",
          "&::before": {
            content: '""',
            position: "absolute",
            width: "200%",
            height: "100%",
            background: `linear-gradient(to right, transparent,${theme.palette.secondary.lighter}, transparent)`,
            top: "0",
            left: "-100%",
            zIndex: 9,
            opacity: 1,
            animation: "wave 1.7s ease infinite",
            animationFillMode: "backwards",
          },
          "@keyframes wave": {
            from: {
              opacity: 0.5,
              transform: "translateX(-100%)",
            },
            to: {
              opacity: 1,
              transform: "translateX(100%)",
            },
          },
        }}
      >
        <img
          src={FoxlyLoader}
          alt="Loading"
          style={{
            display: "block",
            width: "100%",
            height: "auto",
            zIndex: 0,
            position: "relative",
          }}
        />
      </Box>
    </>
  );
};

export default FoxlyLogoLoader;
