import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../api/api";
import * as URL from "../../../api/url";
import { notificationMessageFunction } from "../../../Constants/notificationConst";
import { notificationToast } from "../../../Helpers/common";
import { useTranslation } from "react-i18next";

export const useGetAllDomains = () => {
  const { data, error, refetch, isFetching } = useQuery(
    ["get-domains"],
    async () => {
      try {
        const response = await api.getWithToken(URL.GET_SHORT_URL_DOMAINS);

        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateway");
      }
    },
    {
      enabled: false,
    }
  );

  return { data, error, refetch, isFetching };
};

export const useLinkCloaking = (is_Link_cloaking, payload) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["get-link-cloaking", is_Link_cloaking, payload],
    async () => {
      try {
        const response = await api.postWithToken(
          URL.GET_LINK_CLOAKING,
          payload
        );

        return response?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateway");
      }
    },
    {
      enabled: false,
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};

export const useGetUtmRetarget = (domain_id) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-utm-retarget", domain_id],
    async () => {
      try {
        let query = "";
        if (!!domain_id) {
          query = `?domains=${domain_id}`;
        }

        const response = await api.getWithToken(
          URL.GET_SHORT_URL_UTM_RETARGET + query
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateway");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, refetch, isLoading, isFetching, error };
};

export const useGetAllSpaces = () => {
  const { data, error, refetch } = useQuery(
    ["get-spaces"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.GET_ALL_SPACE + `?sortBy=newest`
        );

        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
    }
  );

  return { data, error, refetch };
};

export const useGetMetadata = (params) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["get-metadata"],
    async () => {
      try {
        const response = await api.getWithToken(URL.GET_METADATA + params);

        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateWays");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};

export const useGetAutoMetadata = (params) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => {
      const response = await api.getWithToken(URL.GET_METADATA + params);
      return response.data.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("get-metadata");
      },
    }
  );
  const { data, error, isLoading, mutate, mutateAsync } = mutation;

  return {
    data: data,
    error: error,
    isLoading: isLoading,
    mutate: mutate,
    execute: mutateAsync,
  };
};

export const useAddLongUrl = (params, payload) => {
  const { t } = useTranslation();
  const { data, error, refetch } = useQuery(
    ["add-longurl"],
    async () => {
      try {
        const response = await api.postWithToken(
          URL.ADD_LONG_URL + params,
          payload
        );
        notificationToast(t("LinkShortSuc"), "success");
        return response.data.data.data;
      } catch (error) {
        const errorMessage = error.data.error.message;
        notificationToast(
          t(notificationMessageFunction(errorMessage)),
          "error"
        );
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch };
};
