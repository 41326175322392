import { Navigate, useRoutes } from "react-router-dom";

// project-imports
import MainRoutes from "./MainRoutes";
import CommonLayout from "../Layouts/CommonLayout";
import { lazy } from "react";
import Loadable from "../Component/Loadable";
import Error404 from "../Pages/ErrorPage/404";
import UnderConstruction from "../Pages/ErrorPage/UnderConstruction";

const PagesLanding = Loadable(lazy(() => import("../Pages/Login")));
const ProtectedUrl = Loadable(lazy(() => import("../Pages/ProtectedUrl")));
const ButtonHold = Loadable(lazy(() => import("../Pages/ButtonHold")));
const ClicksExpired = Loadable(lazy(() => import("../Pages/ClicksExpired")));
const FilesDetails = Loadable(lazy(() => import("../Pages/FilesDetails")));
const FaceBookSetPassword = Loadable(
  lazy(() => import("../Pages/SetPassword/FaceBookSetPassword"))
);
const GoogleSetPassword = Loadable(
  lazy(() => import("../Pages/SetPassword/GoogleSetPassword"))
);
const PagesDashboardLanding = Loadable(
  lazy(() => import("../Pages/Dashboard"))
);
// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <CommonLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/auth/login" />,
        },
        {
          path: "*",
          element: <Error404 path="/auth/login" />,
        },
        {
          path: "/construction",
          element: <UnderConstruction />,
        },
      ],
    },
    {
      path: "/protected-url",
      element: <ProtectedUrl />,
    },
    {
      path: "/button-hold",
      element: <ButtonHold />,
    },
    {
      path: "/clicks-expired",
      element: <ClicksExpired />,
    },
    {
      path: "/file-detail",
      element: <FilesDetails />,
    },
    {
      path: "/set-password",
      element: <FaceBookSetPassword />,
    },
    {
      path: "/setPassword",
      element: <GoogleSetPassword />,
    },
    MainRoutes,
  ]);
}
