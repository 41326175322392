export const errorConst = {
  ALIAS_LENGTH: "AliasLeast1Char",
  ALIAS_MAX_LENGTH: "AliasLengthGreat30Char",
  PASSWORD_MAX_LENGTH: "PasswordReqGreater10",
  PASSWORD_LENGTH: "PasswordReqLeast8",
  ENTER_LINK: "PleEntLink",
  ENTER_DEEP_LINKING: "DeepLinkReq",
  LINK_NOT_VALID: "LinkNotValid",
  SPACES_NOT_ALLOWED: "SpacesNot",
  SPECIAL_NOT_ALLOWED: "SpacialNotAllow",
  EXPIRATION_LINK_REQUIRED: "ExpirationLinkReq",
  CLICK_REQUIRED: "ClicksReq",
  EXPIRATION_LINK_NOT_VALID: "ExpirationLinkValid",
  UTM_MEDIUM_REQUIRED: "UTMMediumReq",
  UTM_CAMPAIGN_REQUIRED: "UTMCamReq",
  UTM_SOURCE_REQUIRED: "UTMSourceReq",
  GOOGLE_ANALYTICS_ID: "GoogleAnalyticsIDBetween10To20Char",
  PIXEL_ID: "MetaPixelIDBet10To20Char",
};
