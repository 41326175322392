// ==============================|| OVERRIDES - BODY ||============================== //

export default function Body() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: '"salt"',
          // scrollbarColor: "white !important",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ffffff00",
            width: "0.8em",
            height: "0.8em",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            border: "2.5px solid #f8f9fa",
            borderRadius: 8,
            backgroundColor: "#b9c0c6",
            minHeight: 12,
            // border: "3px solid #2b2b2b",
          },
          // "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          //   {
          //     backgroundColor: "red",
          //   },
          // "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          //   {
          //     backgroundColor: "#b9c0c6",
          //   },
          // "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          //   {
          //     backgroundColor: "#b9c0c6",
          //   },
          // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          //   backgroundColor: "#b9c0c6",
          // },
          "& .react-datepicker-popper": {
            zIndex: "333 !important",
          },
          "& .time-zone > div ": {
            minHeight: "48px",
            borderRadius: "10px",
          },
          "& .time-zone-setting > .css-13cymwt-control ": {
            paddingLeft: "47px",
          },
          "& .time-zone-setting > .css-t3ipsp-control ": {
            paddingLeft: "47px",
          },
          ".apexcharts-toolbar": {
            zIndex: "1",
          },
          "& .linksTags": {
            position: "relative",
            "& .MuiOutlinedInput-root": {
              paddingLeft: "40px", // Adjust this value according to the width of your icon
              "&::before": {
                content: '""',
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                width: "16px",
                height: "16px",
                backgroundImage: `url('path_to_your_before_image')`, // Add your before image URL here
                backgroundSize: "cover",
              },
            },
            "& .awardIcon": {
              position: "absolute",
              left: "10px",
              top: "12px",
              opacity: ".7",
            },
          },
        },
      },
    },
  };
}
