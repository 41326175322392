import * as url from "../../api/url";
import api from "../../api/api";
import { useQuery } from "react-query";
// api/user/subscription_details

export const useSubscriptionDetails = () => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-subscription-data"],
    async () => {
      try {
        const response = await api.getWithToken(url.SUBSCRIPTIONDETAILS);
        return response.data?.data?.message;
      } catch (error) {
        throw new Error("Failed to fetch subscription details");
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  );

  return { data, error, refetch, isFetching, isLoading };
};
export const useGetPlanData = (id) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-plan-data"],
    async () => {
      try {
        const response = await api.getWithToken(url.PLAN_DETAIL + id);
        return response.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch Plan details");
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useGetPlanDetailData = (id) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-plan-detail-data"],
    async () => {
      try {
        const response = await api.getWithToken(url.PLAN_DETAIL_DATA + id);
        return response.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch Plan details");
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useSubscription = (mode, id) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["subscription-package"],
    async () => {
      try {
        const response = await api.postWithToken(
          `${url.SUBSCRIPTION}?mode=${
            mode === "Lifetime" ? "payment" : "subscription"
          }&price_id=${id}`
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to Subscrib");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
    // { enable: false }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useUnSubscription = () => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["un-subscription-package"],
    async () => {
      try {
        const response = await api.deleteWithToken(`${url.UNSUBSCRIPTION}`);

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to Subscrib");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
    // { enable: false }
  );

  return { data, error, refetch, isFetching, isLoading };
};
export const useLifeTimeUnSubscription = () => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["life-time-un-subscription-package"],
    async () => {
      try {
        const response = await api.postWithToken(
          `${url.UNSUBSCRIPTIONLIFETIME}`
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to Subscrib");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useUsagePlanDetails = () => {
  const { data, isLoading, isFetching, refetch, error } = useQuery(
    ["get-uage-plan-detail"],
    async () => {
      try {
        const response = await api.getWithToken(url.GET_USAGE_PLAN_DETAILS);

        return response?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};
