import api from "../api/api";
import * as url from "../api/url";

export const expireLink = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.EXPIRE_LINK, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteLink = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.DELETE_LINK, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getConfigSetting = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_CONFIG_SETTING)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAddOnDataApi = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_ADD_ON)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getChromeAddOnDataApi = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_CHROME_ADD_ON)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getPurchasedAddOnDataApi = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PURCHASED_ADD_ON_DATA)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const PurchaseAddOnApi = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.GET_ADD_ON, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const PurchaseAddOnWithPabblyApi = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_ON_PABBLY, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
