import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project-imports
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
import { APP_DEFAULT_PATH } from "../../config";
// import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({
  reverse,
  isIcon,
  sx,
  to,
  width = "100",
  disableClick = false,
  handleClick,
}) => (
  <ButtonBase
    disableRipple
    component={Link}
    to={!disableClick && !to ? APP_DEFAULT_PATH : to}
    sx={sx}
    onClick={() => {
      if (handleClick) {
        handleClick();
      }
    }}
  >
    {isIcon ? (
      <LogoIcon width={width} />
    ) : (
      <Logo reverse={reverse} width={width} />
    )}
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string,
  disableClick: PropTypes.bool,
};

export default LogoSection;
