import React from "react";

const Locke = () => {
  return (
    <>
      <svg
        width="200"
        height="134"
        viewBox="0 0 200 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 33761">
          <g id="Group">
            <path
              id="Vector"
              d="M136.243 40.5056V46.3523C136.243 47.2568 134.384 47.9896 132.089 47.9896C129.797 47.9896 127.936 47.2568 127.936 46.3523V40.5056C127.936 32.1234 121.117 25.3066 112.737 25.3066C104.357 25.3066 97.5381 32.1234 97.5381 40.5056V61.6271C97.5381 62.5316 95.6773 63.2644 93.3848 63.2644C91.0902 63.2644 89.2314 62.5316 89.2314 61.6271V40.5056C89.2316 27.5437 99.7754 17 112.737 17C125.699 17 136.243 27.5437 136.243 40.5056Z"
              fill="url(#paint0_linear_1730_16355)"
            />
            <path
              id="Vector_2"
              d="M112.739 17C112.404 17 112.071 17.0088 111.739 17.0227V25.343C112.072 25.3199 112.405 25.3077 112.739 25.3064C121.119 25.3064 127.938 32.1232 127.938 40.5054V46.3521C127.938 47.2566 129.799 47.9894 132.091 47.9894C134.386 47.9894 136.244 47.2566 136.244 46.3521V40.5054C136.244 27.5437 125.701 17 112.739 17Z"
              fill="url(#paint1_linear_1730_16355)"
            />
            <path
              id="Vector_3"
              d="M93.3855 63.2645C95.6781 63.2645 97.5389 62.5317 97.5389 61.6272V40.5057C97.5389 39.8411 97.5863 39.1875 97.6695 38.5452H89.3148C89.2603 39.1973 89.2328 39.8513 89.2324 40.5057V61.6272C89.2324 62.5317 91.0912 63.2645 93.3855 63.2645Z"
              fill="url(#paint2_linear_1730_16355)"
            />
            <path
              id="Vector_4"
              d="M127.937 40.5057V46.3524C127.937 47.2569 129.798 47.9897 132.09 47.9897C134.385 47.9897 136.244 47.2569 136.244 46.3524V40.5057C136.244 39.8454 136.215 39.1918 136.161 38.5452H127.807C127.889 39.1875 127.937 39.8409 127.937 40.5057Z"
              fill="url(#paint3_linear_1730_16355)"
            />
            <path
              id="Vector_5"
              d="M140.097 114.38C121.895 117.874 103.581 117.873 85.3791 114.38C83.1219 113.946 81.2979 111.967 80.7108 109.346C77.1942 93.6515 77.194 77.8715 80.7108 62.176C81.2981 59.5553 83.1219 57.5758 85.3791 57.1426C103.581 53.6487 121.895 53.6489 140.097 57.1426C142.355 57.5758 144.179 59.5553 144.766 62.176C148.283 77.8715 148.282 93.6515 144.766 109.346C144.179 111.967 142.355 113.946 140.097 114.38Z"
              fill="url(#paint4_linear_1730_16355)"
            />
            <path
              id="Vector_6"
              d="M110.514 87.3293H78.0859C78.1949 94.6752 79.0697 102.02 80.7111 109.346C81.2982 111.967 83.1223 113.946 85.3795 114.379C93.741 115.984 102.126 116.85 110.514 116.98V87.3293Z"
              fill="url(#paint5_linear_1730_16355)"
            />
            <path
              id="Vector_7"
              d="M110.514 87.3293H78.0859C78.1949 94.6752 79.0697 102.02 80.7111 109.346C81.2982 111.967 83.1223 113.946 85.3795 114.379C93.741 115.984 102.126 116.85 110.514 116.98V87.3293Z"
              fill="url(#paint6_linear_1730_16355)"
            />
            <path
              id="Vector_8"
              d="M144.765 62.1759C144.178 59.555 142.354 57.5757 140.097 57.1425C129.28 55.0661 118.423 54.224 107.574 54.6154C114.981 54.8825 122.385 55.7249 129.771 57.1425C132.028 57.5757 133.852 59.5552 134.439 62.1759C137.956 77.8714 137.956 93.6514 134.439 109.346C133.852 111.967 132.028 113.946 129.771 114.379C122.386 115.797 114.982 116.639 107.575 116.907C118.424 117.298 129.28 116.456 140.097 114.379C142.354 113.946 144.178 111.967 144.765 109.346C148.282 93.6514 148.282 77.8714 144.765 62.1759Z"
              fill="url(#paint7_linear_1730_16355)"
            />
            <path
              id="Vector_9"
              d="M140.097 57.1422C136.287 56.4111 132.449 55.8312 128.593 55.4039V56.9219C128.986 56.994 129.378 57.067 129.771 57.1424C132.028 57.5756 133.852 59.5551 134.439 62.1758C137.956 77.8713 137.956 93.6513 134.439 109.346C133.852 111.967 132.028 113.946 129.771 114.379C129.378 114.455 128.986 114.528 128.593 114.6V116.118C132.449 115.69 136.287 115.111 140.097 114.38C142.354 113.946 144.179 111.967 144.766 109.346C148.282 93.6515 148.282 77.8715 144.766 62.176C144.179 59.5547 142.355 57.5754 140.097 57.1422Z"
              fill="url(#paint8_linear_1730_16355)"
            />
            <path
              id="Vector_10"
              d="M80.7107 109.346C81.298 111.967 83.1219 113.946 85.3791 114.379C103.581 117.873 121.895 117.873 140.097 114.379C142.355 113.946 144.179 111.967 144.766 109.346C146.524 101.499 147.403 93.6296 147.403 85.7609H78.0732C78.0734 93.6298 78.9525 101.499 80.7107 109.346Z"
              fill="url(#paint9_linear_1730_16355)"
            />
            <path
              id="Vector_11"
              d="M107.575 104.91C103.912 104.91 100.932 101.93 100.932 98.267V91.8601C100.932 90.4713 100.325 89.1762 99.2678 88.3066C96.0924 85.6961 94.3533 81.842 94.4965 77.7326C94.6141 74.3531 96.0293 71.1844 98.4812 68.8102C100.932 66.4373 104.144 65.1237 107.525 65.111H107.575C114.791 65.111 120.662 70.9815 120.662 78.1975C120.662 82.1246 118.92 85.809 115.883 88.306C114.825 89.1762 114.218 90.4715 114.218 91.8603V98.2671C114.218 101.93 111.238 104.91 107.575 104.91Z"
              fill="url(#paint10_linear_1730_16355)"
            />
            <path
              id="Vector_12"
              d="M117.84 78.1974C117.84 72.515 113.223 67.9109 107.535 67.9322C102.06 67.9527 97.5066 72.3584 97.316 77.8308C97.1996 81.1736 98.6828 84.1734 101.059 86.1271C102.772 87.5349 103.753 89.6433 103.753 91.8601V98.2671C103.753 100.378 105.464 102.089 107.575 102.089C109.685 102.089 111.396 100.378 111.396 98.2671V91.9968L111.006 91.8601C111.006 89.6429 111.792 87.3392 113.505 85.9312C115.414 84.3621 117.722 79.7588 117.722 79.7588C117.794 79.2488 117.84 78.7283 117.84 78.1974Z"
              fill="url(#paint11_linear_1730_16355)"
            />
            <path
              id="Vector_13"
              d="M117.84 78.1974C117.84 72.515 113.223 67.9109 107.535 67.9322C102.06 67.9527 97.5066 72.3584 97.316 77.8308C97.1996 81.1736 98.6828 84.1734 101.059 86.1271C102.772 87.5349 103.753 89.6433 103.753 91.8601V98.2671C103.753 100.378 105.464 102.089 107.575 102.089C109.685 102.089 111.396 100.378 111.396 98.2671V91.9968L111.006 91.8601C111.006 89.6429 111.792 87.3392 113.505 85.9312C115.414 84.3621 117.722 79.7588 117.722 79.7588C117.794 79.2488 117.84 78.7283 117.84 78.1974Z"
              fill="url(#paint12_linear_1730_16355)"
            />
            <path
              id="Vector_14"
              d="M117.84 78.1974C117.84 72.515 113.223 67.9109 107.535 67.9322C102.06 67.9527 97.5066 72.3584 97.316 77.8308C97.1996 81.1736 98.6828 84.1734 101.059 86.1271C102.772 87.5349 103.753 89.6433 103.753 91.8601V98.2671C103.753 100.378 105.464 102.089 107.575 102.089C109.685 102.089 111.396 100.378 111.396 98.2671V91.9968L111.006 91.8601C111.006 89.6429 111.792 87.3392 113.505 85.9312C115.414 84.3621 117.722 79.7588 117.722 79.7588C117.794 79.2488 117.84 78.7283 117.84 78.1974Z"
              fill="url(#paint13_linear_1730_16355)"
            />
            <path
              id="Vector_15"
              d="M103.7 90.9781C103.735 91.2694 103.754 91.5637 103.754 91.8602V98.2672C103.754 100.378 105.464 102.089 107.575 102.089C109.686 102.089 111.396 100.378 111.396 98.2672V91.9969L111.006 91.8602C111.006 91.5668 111.021 91.2723 111.048 90.9781H103.7Z"
              fill="url(#paint14_linear_1730_16355)"
            />
            <path
              id="Vector_16"
              d="M103.7 90.9781C103.735 91.2694 103.754 91.5637 103.754 91.8602V98.2672C103.754 100.378 105.464 102.089 107.575 102.089C109.686 102.089 111.396 100.378 111.396 98.2672V91.9969L111.006 91.8602C111.006 91.5668 111.021 91.2723 111.048 90.9781H103.7Z"
              fill="url(#paint15_linear_1730_16355)"
            />
            <path
              id="Vector_17"
              d="M61.3107 89.2068C58.9923 89.2068 57.1134 87.3279 57.1134 85.0115C57.1134 82.6931 58.9923 80.8162 61.3107 80.8162C63.6271 80.8162 65.506 82.6931 65.506 85.0115C65.506 87.3279 63.6271 89.2068 61.3107 89.2068ZM90.8915 79.7588C87.7411 79.7588 84.788 78.1982 83.0321 75.581C80.0009 71.0635 74.8583 68.085 69.0165 68.0615C59.8407 68.0264 52.2197 75.4287 52.0048 84.6025C51.7802 94.1494 59.4521 101.958 68.9501 101.958C74.2938 101.958 79.0595 99.4853 82.1649 95.6201C83.9891 93.3525 86.7137 91.997 89.6239 91.997H111.395V91.8603C111.395 89.6435 112.378 87.5341 114.091 86.1259C115.999 84.5576 117.333 82.3115 117.722 79.7588H90.8915Z"
              fill="url(#paint16_linear_1730_16355)"
            />
            <path
              id="Vector_18"
              d="M74.2687 92.5662C72.8469 92.5662 71.6943 91.4137 71.6943 89.9918V80.0272C71.6943 78.6053 72.8469 77.4528 74.2687 77.4528C75.6906 77.4528 76.8432 78.6053 76.8432 80.0272V89.9918C76.8434 91.4137 75.6906 92.5662 74.2687 92.5662Z"
              fill="url(#paint17_linear_1730_16355)"
            />
            <path
              id="Vector_19"
              d="M65.3761 83.9924C65.4577 84.3192 65.5058 84.6592 65.5058 85.0112C65.5058 87.3276 63.6269 89.2065 61.3105 89.2065C58.9921 89.2065 57.1132 87.3276 57.1132 85.0112C57.1132 84.659 57.1612 84.319 57.2429 83.9924H52.0345C52.0224 84.1952 52.0095 84.3977 52.0048 84.6024C51.7802 94.1493 59.4521 101.958 68.9501 101.958C74.2938 101.958 79.0594 99.4852 82.1649 95.62C83.9891 93.3524 86.7137 91.9969 89.6239 91.9969H111.395V91.8602C111.395 89.6434 112.378 87.534 114.091 86.1258C114.839 85.51 115.497 84.7918 116.044 83.9924H65.3761Z"
              fill="url(#paint18_linear_1730_16355)"
            />
            <path
              id="Vector_20"
              d="M61.3115 79.0428C58.0146 79.0428 55.3428 81.7147 55.3428 85.0116C55.3428 88.3065 58.0146 90.9784 61.3115 90.9784C64.6064 90.9784 67.2783 88.3065 67.2783 85.0116C67.2783 81.7147 64.6064 79.0428 61.3115 79.0428ZM61.3115 88.3085C59.4893 88.3085 58.0146 86.8319 58.0146 85.0116C58.0146 83.1913 59.4893 81.7147 61.3115 81.7147C63.1318 81.7147 64.6084 83.1913 64.6084 85.0116C64.6084 86.8319 63.1318 88.3085 61.3115 88.3085Z"
              fill="url(#paint19_linear_1730_16355)"
            />
            <path
              id="Vector_21"
              d="M90.0701 82.2245C89.8364 82.222 89.6044 82.2659 89.3877 82.3537C89.171 82.4415 88.9739 82.5713 88.8077 82.7358C88.6415 82.9002 88.5096 83.096 88.4196 83.3117C88.3296 83.5275 88.2832 83.7589 88.2832 83.9927C88.2832 84.2265 88.3296 84.458 88.4196 84.6737C88.5096 84.8895 88.6415 85.0852 88.8077 85.2497C88.9739 85.4141 89.171 85.544 89.3877 85.6317C89.6044 85.7195 89.8364 85.7634 90.0701 85.761H114.51C115.587 84.7737 116.442 83.5681 117.017 82.2245H90.0701Z"
              fill="url(#paint20_linear_1730_16355)"
            />
            <path
              id="Vector_22"
              d="M95.7572 86.6687C95.4207 86.6687 95.0979 86.8023 94.8599 87.0403C94.622 87.2783 94.4883 87.601 94.4883 87.9376C94.4883 88.2741 94.622 88.5969 94.8599 88.8349C95.0979 89.0728 95.4207 89.2065 95.7572 89.2065H111.885C112.243 88.2586 112.794 87.3947 113.501 86.6685H95.7572V86.6687Z"
              fill="url(#paint21_linear_1730_16355)"
            />
            <path
              id="Vector_23"
              d="M97.3096 91.9971H111.395V91.8603C111.395 89.6435 112.378 87.5342 114.091 86.126C115.999 84.5576 117.333 82.3115 117.721 79.7588H97.3096V91.9971Z"
              fill="url(#paint22_linear_1730_16355)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1730_16355"
            x1="97.3318"
            y1="21.9152"
            x2="106.773"
            y2="37.8709"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EAF9FA" />
            <stop offset="1" stop-color="#B3DAFE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1730_16355"
            x1="123.495"
            y1="32.4949"
            x2="136.205"
            y2="32.4949"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7BACDF" stop-opacity="0" />
            <stop offset="1" stop-color="#7BACDF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1730_16355"
            x1="93.451"
            y1="51.2881"
            x2="93.451"
            y2="63.5741"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7BACDF" stop-opacity="0" />
            <stop offset="1" stop-color="#7BACDF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1730_16355"
            x1="132.025"
            y1="43.4139"
            x2="132.025"
            y2="48.108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7BACDF" stop-opacity="0" />
            <stop offset="1" stop-color="#7BACDF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1730_16355"
            x1="98.6045"
            y1="62.2344"
            x2="126.809"
            y2="109.182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1730_16355"
            x1="98.5305"
            y1="102.513"
            x2="87.9486"
            y2="89.7034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1730_16355"
            x1="94.2998"
            y1="96.426"
            x2="94.2998"
            y2="91.6477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1730_16355"
            x1="78.019"
            y1="43.0289"
            x2="164.789"
            y2="125.282"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1730_16355"
            x1="138.166"
            y1="85.7607"
            x2="146.128"
            y2="85.7607"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1730_16355"
            x1="112.738"
            y1="99.1667"
            x2="112.738"
            y2="117.407"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1730_16355"
            x1="103.916"
            y1="82.3457"
            x2="90.9094"
            y2="74.7027"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1730_16355"
            x1="101.059"
            y1="85.0105"
            x2="113.603"
            y2="85.0105"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5A5A5A" />
            <stop offset="1" stop-color="#444444" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1730_16355"
            x1="104.336"
            y1="85.0105"
            x2="96.5771"
            y2="85.0105"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#433F43" stop-opacity="0" />
            <stop offset="1" stop-color="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1730_16355"
            x1="106.248"
            y1="79.9713"
            x2="100.47"
            y2="70.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#433F43" stop-opacity="0" />
            <stop offset="1" stop-color="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1730_16355"
            x1="107.19"
            y1="96.5334"
            x2="103.707"
            y2="96.5334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#433F43" stop-opacity="0" />
            <stop offset="1" stop-color="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1730_16355"
            x1="107.548"
            y1="97.2381"
            x2="107.548"
            y2="91.8555"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#433F43" stop-opacity="0" />
            <stop offset="1" stop-color="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1730_16355"
            x1="68.4062"
            y1="69.4398"
            x2="94.7411"
            y2="95.7748"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_1730_16355"
            x1="76.7068"
            y1="85.0096"
            x2="71.6637"
            y2="85.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_1730_16355"
            x1="84.8594"
            y1="89.2469"
            x2="87.1799"
            y2="99.8285"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_1730_16355"
            x1="64.9943"
            y1="88.6942"
            x2="57.3689"
            y2="81.0688"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_1730_16355"
            x1="102.66"
            y1="85.5661"
            x2="102.66"
            y2="82.2961"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_1730_16355"
            x1="103.994"
            y1="89.1858"
            x2="103.994"
            y2="87.0431"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FEF0AE" />
            <stop offset="1" stop-color="#FBC56D" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_1730_16355"
            x1="105.624"
            y1="84.756"
            x2="113.607"
            y2="89.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DC8758" stop-opacity="0" />
            <stop offset="0.215" stop-color="#DD8654" stop-opacity="0.215" />
            <stop offset="0.429" stop-color="#E28448" stop-opacity="0.429" />
            <stop offset="0.642" stop-color="#EA8034" stop-opacity="0.642" />
            <stop offset="0.854" stop-color="#F47B18" stop-opacity="0.854" />
            <stop offset="1" stop-color="#FE7701" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default Locke;
