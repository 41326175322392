import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";

// assets
import { ArrowRight2, Buildings2, Home3 } from "iconsax-react";
import MainCard from "../MainCard";
import { useTranslation } from "react-i18next";

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [subitem, setSubItem] = useState();

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "1rem",
    height: "1rem",
    color: theme.palette.secondary.main,
  };

  useEffect(() => {
    navigation?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu);
      }
      return false;
    });
  });

  let customLocation = location.pathname;
  // only used for component demo breadcrumbs
  // if (customLocation.includes("/components-overview/breadcrumbs")) {
  //   customLocation = "/apps/kanban/board";
  // }

  // if (customLocation.includes("/apps/kanban/backlogs")) {
  //   customLocation = "/apps/kanban/board";
  // }

  // useEffect(() => {
  //   if (customLocation.includes("/apps/profiles/user/payment")) {
  //     setItem(undefined);
  //   }
  // }, [item, customLocation]);

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
          if (collapse.url === customLocation) {
            setMain(collapse);
            setItem();
            setSubItem();
          }
        } else if (collapse.type && collapse.type === "item") {
          if (customLocation === collapse.url) {
            setMain(menu);
            setItem(collapse);
            setSubItem();
          }
        } else if (collapse.type && collapse.type === "subitem") {
          if (
            customLocation === collapse.url ||
            customLocation.includes(collapse.url)
          ) {
            setMain(menu);
            setSubItem(collapse);
          }
        }
        return false;
      });
    }
  };

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? (
    <SeparatorIcon size={12} />
  ) : (
    <ArrowRight2 size={12} />
  );

  let mainContent;
  let itemContent;
  let subitemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = "";
  let itemUrl = null;
  let subitemTitle = "";
  let subitemUrl = null;
  let CollapseIcon;
  let ItemIcon;
  let subItemIcon;

  // collapse item
  if (main && main.type === "collapse" && main.breadcrumbs === true) {
    CollapseIcon = main.icon ? main.icon : Buildings2;
    mainContent = (
      <Typography
        component={Link}
        to={document.location.pathname}
        variant="h6"
        sx={{ textDecoration: "none", display: "flex", alignItems: "center" }}
        color="secondary"
      >
        {icons && <CollapseIcon style={iconSX} />}
        {main.title}
      </Typography>
    );
    breadcrumbContent = (
      <MainCard
        border={card}
        sx={
          card === false
            ? { mb: 3, bgcolor: "transparent", ...sx }
            : { mb: 3, ...sx }
        }
        {...others}
        content={card}
        boxShadow={false}
      >
        <Grid
          container
          direction={rightAlign ? "row" : "column"}
          justifyContent={rightAlign ? "space-between" : "flex-start"}
          alignItems={rightAlign ? "center" : "flex-start"}
          spacing={0.5}
        >
          <Grid item>
            <MuiBreadcrumbs
              aria-label="breadcrumb"
              maxItems={maxItems || 8}
              separator={separatorIcon}
            >
              <Typography
                component={Link}
                to="/"
                variant="h6"
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                color="textPrimary"
              >
                {icons && <Home3 style={iconSX} />}
                {icon && !icons && (
                  <Home3 variant="Bold" style={{ ...iconSX, marginRight: 0 }} />
                )}
                {(!icon || icons) && t("Home")}
              </Typography>
              {mainContent}
            </MuiBreadcrumbs>
          </Grid>
          {title && titleBottom && (
            <Grid item sx={{ mt: card === false ? 0 : 1 }}>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {main.title}
              </Typography>
            </Grid>
          )}
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;
    itemUrl = item.url ? item.url : null;
    ItemIcon = item.icon ? item.icon : Buildings2;
    itemContent = (
      <Typography
        component={Link}
        variant="h6"
        color="secondary"
        to={itemUrl}
        sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}
      >
        {icons && <ItemIcon style={iconSX} />}
        {itemTitle}
      </Typography>
    );
    if (item.breadcrumbs !== false) {
      // main
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={
            card === false
              ? { mb: 3, bgcolor: "transparent", ...sx }
              : { mb: 3, ...sx }
          }
          {...others}
          content={card}
          boxShadow={false}
        >
          <Grid
            container
            direction={rightAlign ? "row" : "column"}
            justifyContent={rightAlign ? "space-between" : "flex-start"}
            alignItems={rightAlign ? "center" : "flex-start"}
            spacing={0.5}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <MuiBreadcrumbs
                aria-label="breadcrumb"
                maxItems={maxItems || 8}
                separator={separatorIcon}
              >
                <Typography
                  component={Link}
                  to="/dashboard"
                  color="textPrimary"
                  variant="h6"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {icons && <Home3 style={iconSX} />}
                  {icon && !icons && (
                    <Home3
                      variant="Bold"
                      style={{ ...iconSX, marginRight: 0 }}
                    />
                  )}
                  {(!icon || icons) && t("Home")}
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            {title && titleBottom && (
              <Grid item sx={{ mt: card === false ? 0 : 1 }}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
              </Grid>
            )}
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  // sub items
  if (subitem && subitem.type === "subitem") {
    if (subitem && subitem?.parents) {
      itemTitle = subitem?.parents?.title;
      itemUrl = subitem.parents.url ? subitem.parents.url : null;
      ItemIcon = subitem?.icon ? item?.icon : Buildings2;
      itemContent = (
        <Typography
          component={Link}
          variant="h6"
          color="secondary"
          to={itemUrl}
          sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}
        >
          {icons && <ItemIcon style={iconSX} />}
          {itemTitle}
        </Typography>
      );
    }
    subitemTitle = subitem.title;
    subitemUrl = subitem.url ? subitem.url : null;
    subItemIcon = subitem.icon ? subitem.icon : Buildings2;
    subitemContent = (
      <Typography
        // component={Link}
        variant="h6"
        color="secondary"
        // to={`${itemUrl}${subitemUrl}`}
        // onClick={() => {
        //   if (!!location.state) {
        //     navigate("/links/stats", {
        //       state: location.state,
        //     });
        //   }
        // }}
        sx={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        {icons && <ItemIcon style={iconSX} />}
        {subitemTitle}
      </Typography>
    );

    // main
    if (item?.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={
            card === false
              ? { mb: 3, bgcolor: "transparent", ...sx }
              : { mb: 3, ...sx }
          }
          {...others}
          content={card}
          boxShadow={false}
        >
          <Grid
            container
            direction={rightAlign ? "row" : "column"}
            justifyContent={rightAlign ? "space-between" : "flex-start"}
            alignItems={rightAlign ? "center" : "flex-start"}
            spacing={0.5}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <MuiBreadcrumbs
                aria-label="breadcrumb"
                maxItems={maxItems || 8}
                separator={separatorIcon}
              >
                <Typography
                  component={Link}
                  to="/dashboard"
                  color="textPrimary"
                  variant="h6"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {icons && <Home3 style={iconSX} />}
                  {icon && !icons && (
                    <Home3
                      variant="Bold"
                      style={{ ...iconSX, marginRight: 0 }}
                    />
                  )}
                  {(!icon || icons) && t("Home")}
                </Typography>
                {mainContent}
                {itemContent}
                {subitemContent}
              </MuiBreadcrumbs>
            </Grid>
            {title && titleBottom && (
              <Grid item sx={{ mt: card === false ? 0 : 1 }}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {subitem?.title}
                </Typography>
              </Grid>
            )}
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }
  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.array,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Breadcrumbs;
