import { Button, CardMedia, Stack, Typography, Box, Link } from "@mui/material";
import avatar from "../../../../assets/images/users/customer-support-1.png";
import Locke from "../../../../assets/Dashboard/Locke";
import MainCard from "../../../../Component/MainCard";
import AnimateButton from "../../../../Component/@extended/AnimateButton";
import { useCurrentPackage } from "../../../../Hooks/Auth/useCurrentPackage";
import CircleLoader from "../../../../Component/CircleLoader";
import { Link as NavigateLink } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const NavCard = () => {
  const { t } = useTranslation();
  const {
    data: ActivePackages,
    isLoading,
    refetch: packageRefetch,
  } = useCurrentPackage();

  useEffect(() => {
    packageRefetch();
  }, []);

  return (
    <MainCard sx={{ bgcolor: "secondary.lighter", m: 3, pb: 3 }}>
      {isLoading ? (
        <Box sx={{ height: "150px" }}>
          <CircleLoader />
        </Box>
      ) : ActivePackages?.user_active_plan === 1 ? (
        <Stack alignItems="center" spacing={2.5}>
          <Locke />
          <Stack alignItems="center" sx={{ maxWidth: "170px", height: "auto" }}>
            <Typography variant="h5">{t("UnlockAllFeatures")}</Typography>
            <Typography
              variant="h6"
              sx={{
                textWrap: "pretty",
                textAlign: "center",
                maxWidth: "170px",
                whiteSpace: "break-spaces",
              }}
              color="secondary"
            >
              {t("GetLifetimeFeatures")}
            </Typography>
          </Stack>
          <AnimateButton>
            <Button
              component={NavigateLink}
              to={"/plans/details"}
              color="warning"
              // target="_blank"
              variant="shadow"
              // size="small"
              // component={Link}
              // href="mailto:info@foxlyme.com"
              // target="_blank"
            >
              {t("UpgradeToPro")}
            </Button>
          </AnimateButton>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={2.5}>
          <CardMedia component="img" image={avatar} />
          <Stack alignItems="center" sx={{ maxWidth: "170px", height: "auto" }}>
            <Typography variant="h5">{t("NeedSupport")}</Typography>
            <Typography
              variant="h6"
              sx={{
                textWrap: "pretty",
                textAlign: "center",
                maxWidth: "170px",
                whiteSpace: "break-spaces",
              }}
              color="secondary"
            >
              {t("ExpertAssistanceFast")}
            </Typography>
          </Stack>
          <AnimateButton>
            <Button
              variant="shadow"
              size="small"
              component={Link}
              href="mailto:info@foxlyme.com"
              target="_blank"
            >
              {t("GetSupport")}
            </Button>
          </AnimateButton>
        </Stack>
      )}
    </MainCard>
  );
};

export default NavCard;
