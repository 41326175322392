import api from "../../api/adminApi";
import * as url from "../../api/adminUrl";

export const addConfigPackage = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_CONFIG_PACKAGE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editConfigPackage = (config_package_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.ADD_CONFIG_PACKAGE + `?config_package_id=${config_package_id}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const buyConfigPackage = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.BUY_CONFIG_PACKAGE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addTelegramBotData = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.TELEGRAM_BOT_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editBuyConfigPackage = (data, configBuyPackageId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.BUY_CONFIG_PACKAGE + `?config_buy_package_id=${configBuyPackageId}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editTelegramBot = (data, config_telegram_bot_id, group) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.TELEGRAM_BOT_CONFIG +
          `?config_telegram_bot_id=${config_telegram_bot_id}&group=${group}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addConfigDomain = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_CONFIG_DOMIAN, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

///add payment

export const addPaypalData = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.PAYPAL_DATA_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPaypalData = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PAYPAL_DATA_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

//add coupon
export const addConfigCoupon = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_CONFIG_COUPON, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllConfig = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllBuyPackages = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BUY_PACKAGES_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addStaticDomain = (payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.STATIC_DOMAIN_CONFIG, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editStaticDomain = (payData, staticDomainId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.STATIC_DOMAIN_CONFIG + `?config_static_domain_id=${staticDomainId}`,
        payData
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editPaypalData = (payData, paypal_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PAYPAL_DATA_CONFIG + `?paypal_id=${paypal_id}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getStaticDomain = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.STATIC_DOMAIN_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTelegramBotData = (Group) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.TELEGRAM_BOT_CONFIG + `?group=${Group}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllDomain = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DOMAIN + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getAllDomainCount = (filter) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DOMAIN_COUNT + filter)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addDomain = (payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.DOMAIN, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editDomain = (domainID, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.DOMAIN + `?domain_id=${domainID}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editDomainList = (domainID, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.ALL_DOMAINS + `?domain_id=${domainID}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const deleteConfig = (domainID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.DOMAIN + `?domain_id=${domainID}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllCoupons = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_COUPON + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getAllCouponsCount = (filters) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_COUPON_COUNT + filters)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteCoupon = (coupon_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.GET_ALL_COUPON + `?coupon_id=${coupon_id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const backToDefaultPackage = (user_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.BACK_TO_DEFAULT + `?user_id=${user_id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

//blocked domain service
export const getAllBlockedDomain = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.BLOCKED_DOMAIN + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addBlockDomain = (domain) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.BLOCKED_DOMAIN, domain)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteBlockedDomain = (domain_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.UPDATE_DOMAIN + domain_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editBlockedDomain = (domain_id, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_DOMAIN + domain_id, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getConfigAds = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ADS_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateConfigAds = (ad_id, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.GET_ADS_CONFIG + ad_id, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getConfigSupportText = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.SUPPORT_TEXT_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddConfigSupportTextApi = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.SUPPORT_TEXT_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditConfigSupportTextApi = (support_text_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.SUPPORT_TEXT_CONFIG + `?support_text_id=${support_text_id}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getConfigAddOn = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.ADD_ON_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getConfigAddOnTrain = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.ADD_ON_TRAIN_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditConfigAddOnApi = (add_on_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.ADD_ON_CONFIG + `?add_on_id=${add_on_id}`, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditConfigAddOnTrainApi = (add_on_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.ADD_ON_TRAIN_CONFIG + `?add_on_id=${add_on_id}`, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddConfigAddOnApi = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_ON_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddConfigAddOnTrainApi = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_ON_TRAIN_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetHelpCenterConfig = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.HELP_CENTER_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddHelpCenterConfig = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.HELP_CENTER_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetTermsConfig = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.TERMS_URL_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddTermsConfig = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.TERMS_URL_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetCommonConfigSettings = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.COMMON_CONFIG_SETTINGS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddUpdateCommonConfigSettings = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.COMMON_CONFIG_SETTINGS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const testDomain = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.TEST_DOMAIN + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetRedirectDomainSettings = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.REDIRECT_DOMAIN_SETTINGS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddUpdateRedirectDomainSettings = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.REDIRECT_DOMAIN_SETTINGS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetUrlRotatingSettings = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.URL_ROTATING)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddUpdateURLRotatingSettings = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.URL_ROTATING, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CheckAllURLSafeBrowsing = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CHECK_SAFE_BROWSING)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetSafeBrowsingConfig = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_SAFE_BROWSING_CONFIG)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddUpdateSafeBrowsingConfig = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.GET_SAFE_BROWSING_CONFIG, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

// ? OPENDNS CONFIG

export const getOpenDns = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.OPENDNS_CONFIG + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addOpenDns = (payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.OPENDNS_CONFIG, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editOpenDns = (dnsID, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.OPENDNS_CONFIG + `?dns_dig_id=${dnsID}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteOpenDns = (dnsID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.OPENDNS_CONFIG + `?dns_dig_id=${dnsID}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllAppDomain = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.APP_DOMAIN + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getAllAppDomainCount = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.APP_DOMAIN_COUNT + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addAppDomain = (payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.APP_DOMAIN, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editAppDomain = (domainID, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.APP_DOMAIN + `?domain_id=${domainID}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteAppConfig = (domainID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.APP_DOMAIN + `?domain_id=${domainID}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DefaultAppDomain = (domainID, payData) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.DEFAULT_DOMAIN + `?domain_id=${domainID}`, payData)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const testAppDomain = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.TEST_APP_DOMAIN + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const verifyAppDomain = (domain_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.VERIFY_APP_DOMAIN + domain_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const verifyAllDomainAtOnce = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CHECK_ALL_DOMAINS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getIpConfig = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.IP_REQUEST)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addIpConfig = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.IP_REQUEST, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editIpConfig = (data, ip_number) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.IP_REQUEST + `?config_ip_Number_id=${ip_number}`, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
