import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  link: "",
};

const linkUrl = createSlice({
  name: "link_url",
  initialState,
  reducers: {
    setLinkURL: (state, action) => {
      state.link = action.payload;
    },
  },
});

export const { setLinkURL } = linkUrl.actions;
export default linkUrl.reducer;
