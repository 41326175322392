import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
// import { CloseCircle, TickCircle } from "iconsax-react";
import {
  useGetPlanData,
  useLifeTimeUnSubscription,
  useSubscription,
  useSubscriptionDetails,
  useUnSubscription,
} from "../../../Hooks/Plan/usePlan";
import { useEffect, useState } from "react";
import CircleLoader from "../../../Component/CircleLoader";
import { useCurrentPackage } from "../../../Hooks/Auth/useCurrentPackage";
import ActionModal from "../../../Component/ActionModal";
import { notificationToast } from "../../../Helpers/common";
import EmptyScreen from "../../../Component/EmptyScreen";
import RightImage from "../../../assets/images/Reseller/RightImage";
import WrongImage from "../../../assets/images/Reseller/WrongImage";
import { useDispatch } from "react-redux";
import { setPlanDetails } from "../../../store/reducers/breadcrumbs";
import { useAccount } from "../../../Hooks/Other/useAccount";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LifeTime = ({ mode }) => {
  const { t } = useTranslation();
  const [open, isOpen] = useState(false);
  const [selected, setSelected] = useState("INR");
  const [loader, setLoader] = useState(true);

  // GetPlanData
  const { data, refetch, isLoading, isFetching } = useGetPlanData(
    packageData?.user_active_plan
  );
  const {
    data: packageData,
    refetch: packageRefetch,
    isFetching: packageIsFetching,
    isLoading: packageIsLoading,
  } = useCurrentPackage();

  const [priceId, setPriceId] = useState("");

  useEffect(() => {
    setPriceId(
      mode !== "None" && mode === "Lifetime"
        ? data?.price_info?.lifetime_price_INR_info?.price_id
        : data?.price_info?.monthly_price_INR_info?.price_id
    );
  }, [data]);

  const {
    data: dataSubscription,
    refetch: refetchSubscription,
    isFetching: fetchingSubscription,
  } = useSubscription(mode, priceId);
  const {
    data: dataUnSubscription,
    refetch: refetchUnSubscription,
    isFetching: fetchingUnSubscription,
  } = useUnSubscription();
  const {
    data: dataLifeTimeUnSubscription,
    refetch: refetchLifeTimeUnSubscription,
    isFetching: fetchingLifeTimeUnSubscription,
  } = useLifeTimeUnSubscription();
  const {
    data: subscriptionDetails,
    refetch: refetchSubscriptionDetails,
    isFetching: fetchingSubscriptionDetails,
  } = useSubscriptionDetails();
  const {
    data: accountDetails,
    refetch: refetchAccount,
    isFetching: fetchingAccount,
  } = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    packageRefetch();
    refetchAccount();
  }, []);

  useEffect(() => {
    if (packageData) {
      refetch();
    }
    if (
      (packageData?.user_active_plan === 3 ||
        packageData?.user_active_plan === 4) &&
      mode !== "None" &&
      mode !== "Lifetime"
    ) {
      refetchSubscriptionDetails();
    }
  }, [packageData]);
  useEffect(() => {
    if (
      packageIsFetching ||
      isFetching ||
      fetchingSubscriptionDetails ||
      fetchingAccount
    ) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [
    packageIsFetching,
    isFetching,
    fetchingSubscriptionDetails,
    fetchingAccount,
  ]);

  const commonDesign = (data) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <> {data === "true" ? <RightImage /> : <WrongImage />}</>
      </Box>
    );
  };

  const onBuyNow = () => {
    isOpen(true);
  };

  const onSubmit = () => {
    refetchSubscription();
  };

  useEffect(() => {
    if (dataSubscription) {
      if (dataSubscription.error) {
        notificationToast(dataSubscription.message, "error");
      }
      if (!dataSubscription.error) {
        window.location.href = dataSubscription.url;
        isOpen(false);
      }
    }
  }, [dataSubscription]);

  const onChange = (e) => {
    setSelected(e.target.value);
    if (e.target.value === "INR") {
      if (mode === "Lifetime") {
        setPriceId(data?.price_info?.lifetime_price_INR_info?.price_id);
      } else {
        setPriceId(data?.price_info?.monthly_price_INR_info?.price_id);
      }
    } else {
      if (mode === "Lifetime") {
        setPriceId(data?.price_info?.lifetime_price_USD_info?.price_id);
      } else {
        setPriceId(data?.price_info?.monthly_price_USD_info?.price_id);
      }
    }
  };

  const onDownGrad = () => {
    if (mode !== "None" && mode === "Lifetime") {
      refetchLifeTimeUnSubscription();
    } else {
      refetchUnSubscription();
    }
  };
  useEffect(() => {
    if (dataUnSubscription) {
      if (dataUnSubscription.error) {
        notificationToast(dataUnSubscription.message, "error");
      }
      if (!dataUnSubscription.error) {
        notificationToast(t("SubCancelSuc"), "success");
        refetch();
        packageRefetch();
        refetchAccount();
        setLoader(true);
      }
    }
  }, [dataUnSubscription]);
  useEffect(() => {
    if (dataLifeTimeUnSubscription) {
      if (dataLifeTimeUnSubscription.error) {
        notificationToast(dataLifeTimeUnSubscription.message, "error");
      }
      if (!dataLifeTimeUnSubscription.error) {
        setLoader(true);
        notificationToast(t("SubCancelSuc"), "success");
        refetch();
        packageRefetch();
        refetchAccount();
      }
    }
  }, [dataLifeTimeUnSubscription]);

  return (
    <Box>
      <ActionModal
        type={"payment"}
        title={t("SelectCurrency")}
        content={
          <Box
            minWidth={"400px"}
            padding={3}
            sx={{ background: "#F2F4F6", borderRadius: "8px" }}
          >
            <RadioGroup
              aria-label=""
              name="currency"
              value={selected}
              onChange={(e) => onChange(e)}
            >
              <FormControlLabel
                value="INR"
                control={<Radio />}
                label="IN (₹)"
                sx={{
                  background: "#fff",
                  margin: "10px",
                  borderRadius: "8px",
                  padding: "10px 5px",
                }}
              />
              <FormControlLabel
                value="USD"
                control={<Radio />}
                label="USA ($)"
                sx={{
                  background: "#fff",
                  margin: "10px",
                  borderRadius: "8px",
                  padding: "10px 5px",
                }}
              />
            </RadioGroup>
          </Box>
        }
        open={open}
        handleClose={() => isOpen(false)}
        handleDelete={onSubmit}
        isLoading={fetchingSubscription}
      />
      {loader ? (
        <>
          <CircleLoader mt="50px" mb="50px" />
        </>
      ) : (
        <>
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box>
                <Typography variant="h3">
                  {t("QualityAccident")}
                  <br /> {t("IntelligentEffort")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              {mode !== "None" && (
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Card
                      sx={{
                        textAlign: "center",
                        maxWidth: "340px",
                        margin: "auto",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5" marginBottom={"1.64px"}>
                          {t("BasicPlan")}
                        </Typography>
                        <Typography variant="h1" margin={"10px auto"}>
                          $0
                        </Typography>
                        <Typography>{t("Lifetime")}</Typography>
                      </CardContent>
                      <CardActions
                        sx={{ justifyContent: "center", marginBottom: "10px" }}
                      >
                        <Button
                          variant={
                            packageData?.user_active_plan === 1
                              ? "outlined"
                              : "contained"
                          }
                          color={
                            packageData?.user_active_plan === 1
                              ? "secondary"
                              : "primary"
                          }
                          disabled
                        >
                          {fetchingUnSubscription ||
                          fetchingLifeTimeUnSubscription
                            ? t("Loading")
                            : packageData?.user_active_plan === 1
                            ? t("CurrentPlan")
                            : t("Downgrade")}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Card
                      sx={{
                        textAlign: "center",
                        maxWidth: "340px",
                        margin: "auto",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5">{t("ProPlan")}</Typography>
                        <Typography variant="h1" margin={"10px auto"}>
                          $
                          {data?.price_info?.monthly_price_USD_info?.price ||
                            "-"}
                        </Typography>
                        <Typography>{mode}</Typography>
                      </CardContent>
                      <CardActions
                        sx={{ justifyContent: "center", marginBottom: "10px" }}
                      >
                        <Button
                          variant={
                            packageData?.user_active_plan === 3 ||
                            packageData?.user_active_plan === 4
                              ? "outlined"
                              : "contained"
                          }
                          color={
                            packageData?.user_active_plan === 3 ||
                            packageData?.user_active_plan === 4
                              ? "secondary"
                              : "primary"
                          }
                          disabled={
                            packageData?.user_active_plan === 3 ||
                            packageData?.user_active_plan === 4
                          }
                          onClick={() => onBuyNow()}
                        >
                          {packageData?.user_active_plan === 3 ||
                          packageData?.user_active_plan === 4
                            ? t("CurrentPlan")
                            : t("BuyNow")}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box paddingLeft={2}>
            <Typography variant="h5">{t("PlanDetails")}</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                background: "#fff",
                border: "2px solid #DBE0E5",
                borderRadius: "8px",
              }}
              marginTop={1}
              display={"flex"}
              padding={2}
              alignItems={"center"}
            >
              <Box width={"50%"}>{t("Price")}</Box>
              <Box width={"25%"} textAlign={"center"}>
                $0.00
              </Box>
              <Box width={"25%"} textAlign={"center"}>
                {(mode === "Lifetime" ? (
                  <>${data?.price_info?.monthly_price_USD_info?.price}</>
                ) : (
                  subscriptionDetails?.plan_cost
                )) || "-"}
              </Box>
            </Box>
            <Box
              sx={{
                background: "#fff",
                border: "2px solid #DBE0E5",
                borderRadius: "8px",
              }}
              marginTop={1}
              display={"flex"}
              padding={2}
              alignItems={"center"}
            >
              <Box width={"50%"}>{t("PlanValidity")}</Box>
              <Box width={"25%"} textAlign={"center"}>
                {t("Lifetime")}
              </Box>
              <Box width={"25%"} textAlign={"center"}>
                {mode}
              </Box>
            </Box>
            <Box
              sx={{
                background: "#fff",
                border: "2px solid #DBE0E5",
                borderRadius: "8px",
              }}
              marginTop={1}
              display={"flex"}
              padding={2}
              alignItems={"center"}
            >
              <Box width={"50%"}>{t("RenewsOn")}</Box>
              <Box width={"25%"} textAlign={"center"}>
                -
              </Box>
              <Box width={"25%"} textAlign={"center"}>
                {mode !== "Lifetime"
                  ? subscriptionDetails?.next_billing_date || "-"
                  : "-"}
              </Box>
            </Box>
          </Box>

          <Box padding={2} className="text-bold" marginTop={2}>
            <Typography variant="h5">{t("Features")}</Typography>
          </Box>
          <Box>
            <Box>
              {!data || !data.features || data?.features?.length === 0 ? (
                <Box display="table" width="100%">
                  <EmptyScreen msg={t("NoFeaturesFound")} />
                </Box>
              ) : (
                data?.features &&
                data?.features?.length !== 0 &&
                data?.features
                  ?.filter((item) => item.is_display)
                  ?.map((obj, index) => {
                    return (
                      <Box
                        sx={{
                          background: "#fff",
                          border: "2px solid #DBE0E5",
                          borderRadius: "8px",
                        }}
                        marginTop={index === 0 ? 0 : 1}
                        display={"flex"}
                        padding={2}
                        key={index}
                        alignItems={"center"}
                      >
                        <Box width={"50%"}>{obj.name}</Box>
                        <Box width={"25%"} textAlign={"center"}>
                          {obj.is_free_plan_value === "true" ||
                          obj.is_free_plan_value === "false" ? (
                            commonDesign(obj.is_free_plan_value)
                          ) : (
                            <Box paddingLeft={"10px"}>
                              {obj.is_free_plan_value}
                            </Box>
                          )}
                        </Box>
                        <Box width={"25%"} textAlign={"center"}>
                          {obj.value === "true" || obj.value === "false" ? (
                            commonDesign(obj.value)
                          ) : (
                            <Box paddingLeft={"10px"}>{obj.value}</Box>
                          )}
                        </Box>
                      </Box>
                    );
                  })
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default LifeTime;
