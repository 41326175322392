import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import MainCard from "../../Component/MainCard";
import { ApexDonutChart } from "./ApexDountChart";
import countryData from "../../Utils/country.json";
import { useTranslation } from "react-i18next";

const DonutChart = ({ platformValue, dynamicState }) => {
  const { t } = useTranslation();
  const getName = (name) => {
    switch (name) {
      case "os":
        return "OperatingSystem";
        break;
      case "browser":
        return "Browser";
        break;

      case "country":
        return "Country";
        break;
      case "city":
        return "City";
        break;
      case "referrer":
        return "Referrer";
        break;
    }
  };

  const getChartData = (data, name) => {
    if (data?.length > 0) {
      switch (name) {
        case "os":
          return {
            series: data?.map((i) => i?.count),
            labels: data?.map((i) => i?.os || ""),
          };
          break;
        case "browser":
          return {
            series: data?.map((i) => i?.count),
            labels: data?.map((i) => i?.browser || ""),
          };
          break;
        case "city":
          return {
            series: data?.map((i) => i?.count),
            labels: data?.map((i) => i?.city || ""),
          };
          break;
        case "referrer":
          return {
            series: data?.map((i) => i?.count),
            labels: data?.map((i) => i?.referer || ""),
          };
          break;
        case "country":
          return {
            series: data?.map((j) => j?.count),
            labels: data?.map(
              (j) =>
                countryData.find((i) => i?.Code === j?.country)?.Name ||
                j?.country ||
                ""
            ),
          };
          break;
      }
    } else {
      return { series: [0], labels: [""] };
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      <MainCard sx={{ height: "100%" }}>
        <Box sx={{ p: 2, pb: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography variant="h5">{t(getName(platformValue))}</Typography>
          </Stack>
        </Box>
        <Box sx={{ minHeight: "410px" }}>
          <ApexDonutChart
            dynamicState={getChartData(dynamicState, platformValue)}
          />
        </Box>
      </MainCard>
    </Box>
  );
};

export default DonutChart;
