import PropTypes from "prop-types";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Stack } from "@mui/material";

// third-party
import { useDropzone } from "react-dropzone";

// project-imports
import RejectionFiles from "./RejectionFiles";
import PlaceholderContent from "./PlaceholderContent";
import { useTranslation } from "react-i18next";

const DropzoneWrapper = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.secondary.main}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ==============================|| UPLOAD - SINGLE FILE ||============================== //

const SingleFileUpload = ({
  error,
  file,
  setFieldValue,
  name,
  sx,
  subTitle,
  accept,
  isLoading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: accept,
    multiple: false,
    onDrop: (acceptedFiles, rejected) => {

      setFieldValue(
        name,
        acceptedFiles
        // .map((file) =>
        //   Object.assign(file, {
        //     preview: URL.createObjectURL(file),
        //   })
        // )
      );
    },
  });

  const thumbs =
    file &&
    file?.map((item) => {
      if (item?.type === "text/csv") {
        return (
          <Box textAlign="center" color={"#3F78FF"}>
            {item.name}
          </Box>
        );
      } else {
        return (
          <img
            key={item?.name}
            alt={item.name}
            src={item.preview}
            style={{
              top: 8,
              left: 8,
              borderRadius: 2,
              position: "absolute",
              width: "calc(100% - 16px)",
              height: "calc(100% - 16px)",
              background: theme.palette.background.paper,
            }}
            onLoad={() => {
              URL.revokeObjectURL(item.preview);
            }}
          />
        );
      }
    });

  const onRemove = () => {
    setFieldValue(name, null);
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && {
            padding: "12px 0",
          }),
        }}
      >
        <input {...getInputProps()} />
        <PlaceholderContent subTitle={subTitle} />
        {thumbs}
      </DropzoneWrapper>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {file && file.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={onRemove}
            disabled={isLoading}
          >
            {t("Remove")}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

SingleFileUpload.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.array,
  setFieldValue: PropTypes.func,
  sx: PropTypes.object,
};

export default SingleFileUpload;
