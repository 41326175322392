import React from "react";

const HelpCenter_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.583 16.8942H11.9163L7.83716 19.6076C7.23216 20.0109 6.41634 19.58 6.41634 18.8467V16.8942C3.66634 16.8942 1.83301 15.0609 1.83301 12.3109V6.81087C1.83301 4.06087 3.66634 2.22754 6.41634 2.22754H15.583C18.333 2.22754 20.1663 4.06087 20.1663 6.81087V12.3109C20.1663 15.0609 18.333 16.8942 15.583 16.8942Z"
          fill="#5B6B79"
        />
        <path
          d="M11.0002 11.1008C10.6243 11.1008 10.3127 10.7892 10.3127 10.4133V10.2209C10.3127 9.15753 11.0918 8.63502 11.3851 8.43335C11.7243 8.20419 11.8343 8.04836 11.8343 7.81003C11.8343 7.35169 11.4585 6.97583 11.0002 6.97583C10.5418 6.97583 10.166 7.35169 10.166 7.81003C10.166 8.18586 9.85435 8.49753 9.47852 8.49753C9.10268 8.49753 8.79102 8.18586 8.79102 7.81003C8.79102 6.59086 9.78099 5.60083 11.0002 5.60083C12.2193 5.60083 13.2093 6.59086 13.2093 7.81003C13.2093 8.85503 12.4393 9.37751 12.1552 9.57001C11.7977 9.80835 11.6877 9.9642 11.6877 10.2209V10.4133C11.6877 10.7983 11.376 11.1008 11.0002 11.1008Z"
          fill="#5B6B79"
        />
        <path
          d="M11 13.3833C10.615 13.3833 10.3125 13.0716 10.3125 12.6958C10.3125 12.32 10.6242 12.0083 11 12.0083C11.3758 12.0083 11.6875 12.32 11.6875 12.6958C11.6875 13.0716 11.385 13.3833 11 13.3833Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default HelpCenter_Icon;
