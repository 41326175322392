// ==============================|| OVERRIDES - LOADING BUTTON ||============================== //

export default function LoadingButton(theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          "& svg": {
            width: 18,
            height: 18,
          },
          paddingLeft: 16,
          paddingRight: 16,
          "&.MuiLoadingButton-loading": {
            opacity: 0.6,
            textShadow: "none",
            backgroundColor: `${theme.palette.secondary.light} !important`,
            "& .MuiLoadingButton-loadingIndicatorStart": {
              left: 12,
            },
            "& .MuiLoadingButton-loadingIndicatorEnd": {
              right: 12,
            },
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: theme.palette.secondary[500],
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.secondary[200], // Custom color when disabled
          },
        },
      },
    },
  };
}
