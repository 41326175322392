import { useMediaQuery, useTheme } from "@mui/material";
import NavCard from "./NavCard";
import Navigation from "./Navigation";
import SimpleBarScroll from "../../../../Component/third_party/SimpleBar";
import { useSelector } from "../../../../store";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <SimpleBarScroll
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <>
        <Navigation />
        {drawerOpen && !matchDownMD && <NavCard />}
      </>
    </SimpleBarScroll>
  );
};

export default DrawerContent;
