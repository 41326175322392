import React from "react";

const AboutPlans_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.167 5.49999V7.71833C20.167 9.16666 19.2503 10.0833 17.802 10.0833H14.667V3.67583C14.667 2.65833 15.5012 1.82416 16.5187 1.83333C17.5178 1.84249 18.4345 2.24583 19.0945 2.90583C19.7545 3.57499 20.167 4.49166 20.167 5.49999Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M1.83301 6.41671V19.25C1.83301 20.0109 2.69467 20.4417 3.29967 19.9834L4.86717 18.81C5.23384 18.535 5.74717 18.5717 6.07717 18.9017L7.59884 20.4325C7.95634 20.79 8.54301 20.79 8.90051 20.4325L10.4405 18.8925C10.7613 18.5717 11.2747 18.535 11.6322 18.81L13.1997 19.9834C13.8047 20.4325 14.6663 20.0017 14.6663 19.25V3.66671C14.6663 2.65837 15.4913 1.83337 16.4997 1.83337H6.41634H5.49967C2.74967 1.83337 1.83301 3.47421 1.83301 5.50004V6.41671Z"
          fill="#5B6B79"
        />
        <path
          d="M11 11.2384H8.25C7.87417 11.2384 7.5625 11.5501 7.5625 11.9259C7.5625 12.3017 7.87417 12.6134 8.25 12.6134H11C11.3758 12.6134 11.6875 12.3017 11.6875 11.9259C11.6875 11.5501 11.3758 11.2384 11 11.2384Z"
          fill="#5B6B79"
        />
        <path
          d="M8.25 8.94666H11C11.3758 8.94666 11.6875 8.63499 11.6875 8.25916C11.6875 7.88332 11.3758 7.57166 11 7.57166H8.25C7.87417 7.57166 7.5625 7.88332 7.5625 8.25916C7.5625 8.63499 7.87417 8.94666 8.25 8.94666Z"
          fill="#5B6B79"
        />
        <path
          d="M5.47233 7.34253C4.959 7.34253 4.55566 7.75503 4.55566 8.2592C4.55566 8.76336 4.96816 9.17586 5.47233 9.17586C5.9765 9.17586 6.389 8.76336 6.389 8.2592C6.389 7.75503 5.9765 7.34253 5.47233 7.34253Z"
          fill="#5B6B79"
        />
        <path
          d="M5.47233 11.0092C4.959 11.0092 4.55566 11.4217 4.55566 11.9258C4.55566 12.43 4.96816 12.8425 5.47233 12.8425C5.9765 12.8425 6.389 12.43 6.389 11.9258C6.389 11.4217 5.9765 11.0092 5.47233 11.0092Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default AboutPlans_Icon;
