import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Add, Logout } from "iconsax-react";
import { useTranslation } from "react-i18next";
import LogoutImage from "../../../../../assets/images/logout.png";
import { STORAGEKEY } from "../../../../../Component/Constant";
import { useTheme } from "@mui/material/styles";

const LogoutModal = ({ handleClose, open }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem(STORAGEKEY.THEME_MODE, theme.palette.mode);
    window.location.href = "/auth/login";
  };

  return (
    <>
      <Dialog handleClose={handleClose} open={open}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle>{t("Logout")}</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 1.5 }}>
            <IconButton color="secondary" onClick={handleClose}>
              <Add style={{ transform: "rotate(45deg)" }} />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent
          dividers
          sx={{ pt: 4, px: 8, borderBottom: "1px solid transparent" }}
        >
          <img src={LogoutImage} alt="qrcode" height={350} width={350} />
        </DialogContent>
        <DialogContent sx={{ textAlign: "center", py: 0 }}>
          {" "}
          <h3>{t("AreYouSureLogout")}</h3>
        </DialogContent>
        <DialogContent sx={{ margin: "auto" }}>
          <Button
            variant="contained"
            sx={{ marginX: "5px" }}
            onClick={(e) => handleClose(e)}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<Logout variant="Bulk" size={18} />}
            onClick={() => handleLogout()}
          >
            {t("Logout")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LogoutModal;
