import React from "react";

const CreatedAt = () => {
  return (
    <>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.2915 7.71349V4.3335C36.2915 3.44516 35.5548 2.7085 34.6665 2.7085C33.7782 2.7085 33.0415 3.44516 33.0415 4.3335V7.5835H18.9582V4.3335C18.9582 3.44516 18.2215 2.7085 17.3332 2.7085C16.4448 2.7085 15.7082 3.44516 15.7082 4.3335V7.71349C9.85818 8.25516 7.01985 11.7435 6.58651 16.9218C6.54318 17.5502 7.06318 18.0702 7.66985 18.0702H44.3298C44.9582 18.0702 45.4782 17.5285 45.4132 16.9218C44.9798 11.7435 42.1415 8.25516 36.2915 7.71349Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M45.5 23.4867V27.2567C45.5 28.5783 44.33 29.5967 43.03 29.38C42.4233 29.2934 41.795 29.2283 41.1667 29.2283C34.6017 29.2283 29.25 34.58 29.25 41.145C29.25 42.1417 29.64 43.5284 30.0517 44.7851C30.5283 46.1934 29.4883 47.645 27.9933 47.645H17.3333C9.75 47.645 6.5 43.3117 6.5 36.8117V23.465C6.5 22.2733 7.475 21.2983 8.66667 21.2983H43.3333C44.525 21.32 45.5 22.295 45.5 23.4867Z"
          fill="white"
        />
        <path
          d="M41.1667 32.5C36.3783 32.5 32.5 36.3783 32.5 41.1667C32.5 42.7917 32.955 44.33 33.7567 45.63C35.2517 48.1433 38.0033 49.8333 41.1667 49.8333C44.33 49.8333 47.0817 48.1433 48.5767 45.63C49.3783 44.33 49.8333 42.7917 49.8333 41.1667C49.8333 36.3783 45.955 32.5 41.1667 32.5ZM45.6517 40.235L41.0367 44.5034C40.7333 44.785 40.3217 44.9366 39.9317 44.9366C39.52 44.9366 39.1083 44.785 38.7833 44.46L36.6383 42.315C36.01 41.6867 36.01 40.6467 36.6383 40.0183C37.2667 39.39 38.3067 39.39 38.935 40.0183L39.975 41.0584L43.4417 37.8517C44.0917 37.245 45.1317 37.2884 45.7383 37.9384C46.345 38.5884 46.3017 39.6067 45.6517 40.235Z"
          fill="white"
        />
        <path
          d="M18.4167 32.4998C17.8533 32.4998 17.29 32.2615 16.8783 31.8715C16.4883 31.4598 16.25 30.8965 16.25 30.3331C16.25 29.7698 16.4883 29.2065 16.8783 28.7948C17.3767 28.2965 18.135 28.0581 18.85 28.2098C18.98 28.2315 19.11 28.2748 19.24 28.3398C19.37 28.3831 19.5 28.4482 19.63 28.5348C19.7383 28.6215 19.8467 28.7082 19.955 28.7948C20.345 29.2065 20.5833 29.7698 20.5833 30.3331C20.5833 30.8965 20.345 31.4598 19.955 31.8715C19.8467 31.9581 19.7383 32.0448 19.63 32.1314C19.5 32.2181 19.37 32.2832 19.24 32.3265C19.11 32.3915 18.98 32.4348 18.85 32.4565C18.6983 32.4782 18.5467 32.4998 18.4167 32.4998Z"
          fill="white"
        />
        <path
          d="M26.0007 32.5001C25.4373 32.5001 24.874 32.2617 24.4623 31.8717C24.0723 31.4601 23.834 30.8967 23.834 30.3334C23.834 29.7701 24.0723 29.2068 24.4623 28.7951C25.2857 27.9934 26.7373 27.9934 27.539 28.7951C27.929 29.2068 28.1673 29.7701 28.1673 30.3334C28.1673 30.8967 27.929 31.4601 27.539 31.8717C27.1273 32.2617 26.564 32.5001 26.0007 32.5001Z"
          fill="white"
        />
        <path
          d="M18.4167 40.0834C17.8533 40.0834 17.29 39.845 16.8783 39.455C16.4883 39.0434 16.25 38.4801 16.25 37.9167C16.25 37.3534 16.4883 36.7901 16.8783 36.3784C17.095 36.1834 17.3117 36.0317 17.5933 35.9234C18.395 35.5767 19.3483 35.7717 19.955 36.3784C20.345 36.7901 20.5833 37.3534 20.5833 37.9167C20.5833 38.4801 20.345 39.0434 19.955 39.455C19.5433 39.845 18.98 40.0834 18.4167 40.0834Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default CreatedAt;
