import React from "react";

const Domains_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1694 13.4475L13.3652 17.2517C13.2186 17.3984 13.0811 17.6825 13.0444 17.8842L12.8336 19.3417C12.7602 19.8642 13.1269 20.24 13.6494 20.1575L15.1069 19.9467C15.3086 19.9192 15.5927 19.7725 15.7394 19.6259L19.5436 15.8217C20.1944 15.1709 20.5061 14.4009 19.5436 13.4384C18.5902 12.485 17.8294 12.7875 17.1694 13.4475Z"
          fill="#5B6B79"
        />
        <path
          d="M16.6289 13.9884C16.9497 15.1434 17.8572 16.0417 19.0122 16.3717L16.6289 13.9884Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M1.86111 13.4109C1.86111 13.4384 1.84277 13.4751 1.84277 13.5026C2.68611 15.1892 4.06112 16.5734 5.74778 17.4075C5.77528 17.4075 5.81194 17.3892 5.83944 17.3892C5.52777 16.3259 5.28945 15.235 5.11528 14.1442C4.01528 13.9609 2.92444 13.7226 1.86111 13.4109Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M17.4811 5.89414C16.6194 4.08831 15.1619 2.63082 13.3652 1.77832C13.6952 2.86915 13.9702 3.98749 14.1536 5.10582C15.2719 5.28916 16.3902 5.55498 17.4811 5.89414Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M1.76953 5.89419C2.86953 5.56419 3.98788 5.2892 5.10621 5.10587C5.28954 4.01503 5.51871 2.93337 5.83037 1.87004C5.80287 1.87004 5.7662 1.85168 5.7387 1.85168C4.01537 2.70418 2.61286 4.14335 1.76953 5.89419Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M12.6687 4.9133C12.4487 3.72163 12.1737 2.52998 11.7795 1.37498C11.7612 1.31081 11.7612 1.25582 11.752 1.18249C11.0737 1.01749 10.3587 0.907471 9.62537 0.907471C8.88287 0.907471 8.17704 1.00832 7.48954 1.18249C7.48037 1.24665 7.48953 1.30165 7.4712 1.37498C7.0862 2.52998 6.80203 3.72163 6.58203 4.9133C8.60786 4.6933 10.6429 4.6933 12.6687 4.9133Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M4.91306 6.58167C3.71222 6.80167 2.52972 7.07667 1.37472 7.47083C1.31055 7.48917 1.25555 7.48917 1.18222 7.49833C1.01722 8.17666 0.907227 8.89167 0.907227 9.62501C0.907227 10.3675 1.00805 11.0733 1.18222 11.7608C1.24638 11.77 1.30139 11.7608 1.37472 11.7792C2.52972 12.1642 3.71222 12.4483 4.91306 12.6683C4.69306 10.6425 4.69306 8.6075 4.91306 6.58167Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M18.058 7.49833C17.9938 7.49833 17.9388 7.48917 17.8655 7.47083C16.7105 7.08583 15.5188 6.80167 14.3271 6.58167C14.5563 8.6075 14.5563 10.6425 14.3271 12.6592C15.5188 12.4392 16.7105 12.1642 17.8655 11.77C17.9296 11.7517 17.9846 11.7608 18.058 11.7517C18.223 11.0642 18.333 10.3583 18.333 9.61583C18.333 8.89166 18.2321 8.18583 18.058 7.49833Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M6.58203 14.3367C6.80203 15.5375 7.07703 16.72 7.4712 17.875C7.48953 17.9392 7.48037 17.9941 7.48954 18.0675C8.17704 18.2325 8.88287 18.3425 9.62537 18.3425C10.3587 18.3425 11.0737 18.2416 11.752 18.0675C11.7612 18.0033 11.7612 17.9483 11.7795 17.875C12.1645 16.72 12.4487 15.5375 12.6687 14.3367C11.6604 14.4467 10.6429 14.5292 9.62537 14.5292C8.60787 14.52 7.59036 14.4467 6.58203 14.3367Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M6.37129 6.3708C6.09629 8.53413 6.09629 10.7158 6.37129 12.8883C8.53462 13.1633 10.7163 13.1633 12.8888 12.8883C13.1638 10.725 13.1638 8.5433 12.8888 6.3708C10.7163 6.0958 8.53462 6.0958 6.37129 6.3708Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Domains_Icon;
