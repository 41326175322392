import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Crown1 } from "iconsax-react";

const UsagePlanCard = ({ title, used, limit, subTitle, proPlan }) => {
  const extractNumericValue = (sizeStr) => {
    if (typeof sizeStr === "string") {
      const match = sizeStr.match(/^([\d.]+)\s*(MB|GB|KB)?$/);
      if (match) {
        const size = parseFloat(match[1]);
        return size;
      }
    }
    return sizeStr;
  };

  const usedValue = extractNumericValue(used);
  const limitValue = extractNumericValue(limit);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: "100%",
    height: 12,
    borderRadius: 5,
    zIndex: 9,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: used == limit ? "#d34547" : "#02af63",
    },
  }));

  const calculateProgress = (used, total) => {
    if (!used || !total || isNaN(total)) return 0;
    return (used / total) * 100;
  };
  return (
    <>
      <Stack spacing={0.65}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "15px", fontWeight: 500, mr: "8px" }}>
            {title || ""}
          </Typography>
          {proPlan && (
            <Chip
              icon={<Crown1 />}
              size="small"
              variant="outlined"
              label="Pro"
              color="warning"
              sx={{
                "& .MuiChip-icon": {
                  width: "15px",
                  height: "15px",
                },
                "& .MuiChip-label": {
                  fontSize: "13px",
                },
              }}
            />
          )}
        </Box>
        <BorderLinearProgress
          variant="determinate"
          value={calculateProgress(usedValue, limitValue)}
        />
        <Typography
          sx={{ fontSize: "12px", fontWeight: 500, color: "#909398" }}
        >
          {subTitle || ""}
        </Typography>
      </Stack>
    </>
  );
};

export default UsagePlanCard;
