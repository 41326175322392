import React from "react";

const Spaces_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.305 10.835L19.1583 10.6334C18.9017 10.3217 18.5992 10.0742 18.2509 9.89088C17.7834 9.62505 17.2517 9.48755 16.7017 9.48755H5.28918C4.73918 9.48755 4.21668 9.62505 3.74002 9.89088C3.38252 10.0834 3.06168 10.3492 2.79585 10.6792C2.27335 11.3484 2.02585 12.1734 2.10835 12.9984L2.44752 17.2792C2.56668 18.5717 2.72252 20.1667 5.62835 20.1667H16.3717C19.2775 20.1667 19.4242 18.5717 19.5525 17.27L19.8917 13.0075C19.9742 12.2375 19.7725 11.4675 19.305 10.835ZM13.1909 15.895H8.80002C8.44252 15.895 8.15835 15.6017 8.15835 15.2534C8.15835 14.905 8.44252 14.6117 8.80002 14.6117H13.1909C13.5484 14.6117 13.8325 14.905 13.8325 15.2534C13.8325 15.6109 13.5484 15.895 13.1909 15.895Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M3.09863 10.3675C3.3003 10.1842 3.50197 10.0192 3.7403 9.89087C4.2078 9.62504 4.73947 9.48754 5.28947 9.48754H16.7111C17.2611 9.48754 17.7836 9.62504 18.2603 9.89087C18.4986 10.0192 18.7095 10.1842 18.902 10.3767V9.89087V9.00171C18.902 5.72921 17.9028 4.73004 14.6303 4.73004H12.4486C12.0453 4.73004 12.0361 4.72087 11.7978 4.40921L10.6978 2.93337C10.1753 2.25504 9.7628 1.83337 8.45197 1.83337H7.3703C4.0978 1.83337 3.09863 2.83254 3.09863 6.10504V9.90004V10.3675Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Spaces_Icon;
