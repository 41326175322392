import api from "../api/api";
import * as url from "../api/url";

export const userCheckToken = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VALID_TOKEN, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
