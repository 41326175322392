import React from "react";

const Clicks = () => {
  return (
    <>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M33.5405 4.33325H16.3155C8.8188 4.33325 4.35547 8.79656 4.35547 16.2932V33.5183C4.35547 41.0149 8.84047 45.4782 16.3155 45.4782H33.5405C41.0371 45.4782 45.5005 41.0149 45.5005 33.5183V16.2932C45.5005 8.79656 41.0155 4.33325 33.5405 4.33325Z"
          fill="white"
        />
        <path
          d="M47.5803 40.82L44.0486 42.0117C43.0736 42.3367 42.2936 43.095 41.9686 44.0917L40.777 47.6233C39.7586 50.6783 35.4686 50.6133 34.5153 47.5583L30.507 34.6667C29.727 32.11 32.0886 29.7266 34.6236 30.5283L47.537 34.5367C50.5703 35.49 50.6136 39.8016 47.5803 40.82Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default Clicks;
