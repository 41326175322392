import { CircularProgress, Box } from "@mui/material";

export default function CircleLoader({
  mt = "100px",
  mb = "auto",
  height = "auto",
  sx = {},
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: mt,
        marginBottom: mb,
        height: height,
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
