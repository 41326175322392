import React from "react";

function RightImage() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.9974"
        cy="13.9998"
        r="12.9167"
        fill="white"
        stroke="#4680FF"
        stroke-width="0.833333"
      />
      <circle cx="13.9954" cy="14" r="10.3704" fill="#4680FF" />
      <g clip-path="url(#clip0_2044_18472)">
        <path
          d="M12.8568 14.9033C13.0342 14.5442 13.3424 14.0066 13.8436 13.3668C14.6687 12.3133 16.2 10.7686 18.8157 9.37531L18.8157 9.37531C18.8845 9.3387 18.9645 9.3292 19.0399 9.34868C19.1153 9.36816 19.1807 9.41522 19.2231 9.48055C19.2655 9.54588 19.2819 9.62474 19.269 9.70156C19.2561 9.77834 19.2149 9.84751 19.1535 9.89541L12.8568 14.9033ZM12.8568 14.9033L11.4318 13.2638L11.4318 13.2638C11.4026 13.2302 11.3665 13.2033 11.3259 13.1848C11.2854 13.1664 11.2414 13.1568 11.1969 13.1568H11.1969H9.02479C8.96427 13.1568 8.90506 13.1744 8.85442 13.2076C8.80377 13.2407 8.76388 13.2879 8.73963 13.3433C8.71537 13.3988 8.70781 13.4601 8.71787 13.5198C8.72792 13.5794 8.75515 13.6349 8.79623 13.6793L8.79624 13.6793L13.3088 18.5606C13.3088 18.5606 13.3088 18.5606 13.3088 18.5606C13.3337 18.5876 13.3632 18.6099 13.3959 18.6266L13.3702 18.6607L13.5373 18.6607C13.6068 18.6607 13.6743 18.6374 13.7291 18.5945C13.7839 18.5516 13.8227 18.4916 13.8394 18.4241C14.5314 15.6254 15.9382 13.4563 16.9973 12.129L16.9321 12.0771L16.9973 12.129C17.5725 11.4081 18.1099 10.8509 18.5045 10.4733C18.7018 10.2845 18.8633 10.1406 18.9759 10.0436C19.0322 9.99511 19.0762 9.95833 19.1064 9.93353L19.1411 9.90534L19.1502 9.89804L19.1526 9.89611L19.1533 9.89559L19.1534 9.89548L12.8568 14.9033Z"
          fill="white"
          stroke="white"
          stroke-width="0.166667"
        />
      </g>
      <defs>
        <clipPath id="clip0_2044_18472">
          <rect
            width="11.8519"
            height="11.8519"
            fill="white"
            transform="translate(8.07031 8.07397)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RightImage;
