import React from "react";

const CouponLicense_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M18.1409 3.86834C15.4276 1.16417 11.0276 1.16417 8.33259 3.86834C6.43509 5.7475 5.86676 8.45167 6.60009 10.835L2.29176 15.1433C1.98926 15.455 1.77843 16.0692 1.84259 16.5092L2.11759 18.5075C2.21843 19.1675 2.83259 19.7908 3.49259 19.8825L5.49093 20.1575C5.93093 20.2217 6.54509 20.02 6.85676 19.6992L7.60843 18.9475C7.79176 18.7733 7.79176 18.48 7.60843 18.2967L5.83009 16.5183C5.56426 16.2525 5.56426 15.8125 5.83009 15.5467C6.09593 15.2808 6.53593 15.2808 6.80176 15.5467L8.58926 17.3342C8.76343 17.5083 9.05676 17.5083 9.23093 17.3342L11.1743 15.4C13.5484 16.1425 16.2526 15.565 18.1409 13.6858C20.8451 10.9817 20.8451 6.5725 18.1409 3.86834ZM13.2918 11C12.0268 11 11.0001 9.97334 11.0001 8.70834C11.0001 7.44334 12.0268 6.41667 13.2918 6.41667C14.5568 6.41667 15.5834 7.44334 15.5834 8.70834C15.5834 9.97334 14.5568 11 13.2918 11Z"
          fill="#5B6B79"
        />
        <path
          d="M13.2917 11C14.5573 11 15.5833 9.97395 15.5833 8.70829C15.5833 7.44264 14.5573 6.41663 13.2917 6.41663C12.026 6.41663 11 7.44264 11 8.70829C11 9.97395 12.026 11 13.2917 11Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default CouponLicense_Icon;
