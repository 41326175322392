import { useTranslation } from "react-i18next";
import NoData from "../assets/images/EmptyScreen.png";
import { TableCell, TableRow, Typography } from "@mui/material";

const EmptyScreen = ({ msg, colSpan, height = "478px" }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ height: height }}>
        <Typography align="center" color="secondary">
          <img src={NoData} alt="No Data" />
          <p>{t("Sorry")}</p>
          <p>{msg}</p>
        </Typography>
      </TableCell>
    </TableRow>
  );
};
export default EmptyScreen;
