import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Dot from "../../../../../Component/@extended/Dot";
import useConfig from "../../../../../Hooks/useConfig";
import { MenuOrientation, ThemeMode } from "../../../../../config";
import { dispatch, useSelector } from "../../../../../store";
import { activeItem, openDrawer } from "../../../../../store/reducers/menu";
import { Lock1 } from "iconsax-react";
import ActionModal from "../../../../../Component/ActionModal";
import { useTranslation } from "react-i18next";

// ==============================|| NAVIGATION - ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { menuOrientation } = useConfig();

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon variant="Bulk" size={drawerOpen ? 20 : 22} />
  ) : (
    false
  );

  const { pathname } = useLocation();

  const isSelected =
    openItem.findIndex((id) => id === item.id) > -1 ||
    pathname.includes(item?.url);
  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes("product-details")) {
      if (item.url && item.url.includes("product-details")) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes("kanban")) {
      if (item.url && item.url.includes("kanban")) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname.includes(item.url) || pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor =
    theme.palette.mode === ThemeMode.DARK ? "secondary.400" : "secondary.main";
  const iconSelectedColor = "primary.main";

  const [modalData, setModalData] = useState(null);

  return (
    <>
      <ActionModal
        type="upgrade"
        title={modalData?.title}
        content={<Box>{modalData?.subtitle}</Box>}
        open={modalData !== null}
        handleClose={() => setModalData(null)}
        handleDelete={() => {
          navigate("/plans/details");
          setModalData(null);
        }}
      />
      {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
        <ListItemButton
          // component={Link}
          target={itemTarget}
          disabled={item.disabled}
          selected={isSelected}
          onClick={() => {
            if (!item?.is_locked) {
              navigate(item?.url);
            }
          }}
          sx={{
            zIndex: 1201,
            pl: drawerOpen ? `${level * 20}px` : 1.5,
            py: !drawerOpen && level === 1 ? 1.25 : 1,
            ...(drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
            ...(drawerOpen &&
              level === 1 && {
                mx: 1.25,
                my: 0.5,
                borderRadius: 1,
                "&:hover": {
                  bgcolor:
                    theme.palette.mode === ThemeMode.DARK
                      ? "divider"
                      : "secondary.200",
                },
                "&.Mui-selected": {
                  color: iconSelectedColor,
                  "&:hover": {
                    color: iconSelectedColor,
                  },
                },
              }),
            ...(!drawerOpen && {
              px: 2.75,
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
          }}
          {...(downLG && {
            onClick: () => {
              if (!item?.is_locked) {
                navigate(item?.url);
                dispatch(openDrawer(false));
              }
            },
          })}
          className={`my-${item?.index}-step`}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 38,
                color: isSelected ? iconSelectedColor : textColor,
                ...(!drawerOpen &&
                  level === 1 && {
                    borderRadius: 1,
                    width: 46,
                    height: 46,
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === ThemeMode.DARK
                          ? "secondary.100"
                          : "secondary.200",
                    },
                  }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor:
                      theme.palette.mode === ThemeMode.DARK
                        ? "secondary.100"
                        : "primary.lighter",
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === ThemeMode.DARK
                          ? "secondary.200"
                          : "primary.lighter",
                    },
                  }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          {!itemIcon && drawerOpen && (
            <ListItemIcon
              sx={{
                minWidth: 30,
              }}
            >
              <Dot
                size={isSelected ? 6 : 5}
                color={isSelected ? "primary" : "secondary"}
              />
            </ListItemIcon>
          )}

          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    color: isSelected ? iconSelectedColor : textColor,
                    fontWeight: isSelected ? 500 : 400,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {item.title}
                  {item?.is_locked && (
                    <Lock1
                      size="20"
                      onClick={() => {
                        if (item?.id === "Bulk Links") {
                          setModalData({
                            title: t("BulkLinks"),
                            subtitle: t("UpgradeAddBulkLinks"),
                          });
                        }
                        if (item?.id === "Link Cloaking") {
                          setModalData({
                            title: t("LinkCloaking"),
                            subtitle: t("UpgradeAddLinkCloaking"),
                          });
                        }
                        if (item?.id === "Favourite Links") {
                          setModalData({
                            title: t("FavouriteLinks"),
                            subtitle: t("UpgradeAddFavouriteLinks"),
                          });
                        }
                        if (item?.id === "Reseller") {
                          setModalData({
                            title: t("Reseller"),
                            subtitle: t("UpgradeAddReseller"),
                          });
                        }
                      }}
                    />
                  )}
                </Typography>
              }
            />
          )}
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          component={Link}
          to={item.url}
          target={itemTarget}
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            ...(drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                bgcolor: "transparent",
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor: "transparent",
                },
              },
            }),
            ...(!drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 36,
                ...(!drawerOpen && {
                  borderRadius: 1,
                  width: 36,
                  height: 36,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "transparent",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : textColor,
                  fontWeight: isSelected ? 500 : 400,
                }}
              >
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              sx={{ ml: 1 }}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
