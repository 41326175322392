import * as URL from "../../api/url";
import api from "../../api/api";
import { useQuery } from "react-query";

export const useCurrentPackage = () => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["current-package"],
    async () => {
      try {
        const response = await api.getWithToken(URL.CURRENT_PACKAGE);
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch Package");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
    // { enable: false }
  );

  return { data, error, refetch, isFetching, isLoading };
};

