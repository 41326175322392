import * as URL from "../../api/url";
import api from "../../api/api";
import { useQuery, useMutation, useQueryClient } from "react-query";

export const useGetBulkLinkTotalCount = (bunch_id) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["get-BulkLink-count-data"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.SPACE_ANALYTICS_COUNT + `?bunch_id=${bunch_id}`
        );
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, isLoading, error, refetch, isFetching };
};

export const useGetBulkLinkClicksAnalytics = () => {
  const postRequest = async ({ get, url, payload }) => {
    if (!url) return;
    const baseURL = get
      ? api.getWithToken(URL.SPACE_ANALYTICS + `${url}`)
      : api.postWithToken(URL.SPACE_ANALYTICS + `${url}`, payload);
    try {
      const response = await baseURL;
      return (
        response.data.data.daysData ||
        response.data.data.hoursData ||
        response.data.data.thisMonthData ||
        response.data.data.lastMonthData
      );
    } catch (error) {
      return error.response?.data;
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error, data } = useMutation(postRequest);

  return { mutate, isLoading, error, data };
};

export const useGetBulkLinkUrlAnalytics = () => {
  const postRequest = async ({ get, url, payload }) => {
    if (!url) return;
    const baseURL = get
      ? api.getWithToken(URL.SPACE_ANALYTICS + `${url}`)
      : api.postWithToken(URL.SPACE_ANALYTICS + `${url}`, payload);
    try {
      const response = await baseURL;
      return (
        response.data.data.daysData ||
        response.data.data.hoursData ||
        response.data.data.thisMonthData ||
        response.data.data.lastMonthData
      );
    } catch (error) {
      return error.response?.data;
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error, data } = useMutation(postRequest);

  return { mutate, isLoading, error, data };
};

export const useGetBulkLinkStateAnalytics = ({
  lastPath,
  day,
  auth,
  search,
  page,
  limit,
  platform,
  type,
  _id = "",
  extra = "",
  get,
  payload,
  uniq_id = "",
}) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["get-BulkLink-analytics-data"],
    async () => {
      const apiType = get ? api.getWithToken : api.postWithToken;
      const baseURL = `${
        URL.SPACE_STATE_GRAPH
      }${lastPath}&auth=${auth}&search=${encodeURIComponent(
        search
      )}&page=${page}&limit=${limit}&platform=${platform}&type=${type}&bunch_id=${_id}${extra}`;
      try {
        const response = await apiType(baseURL, payload);
        return response?.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, isLoading, error, refetch, isFetching };
};

export const useGetBulkLinkSingleAnalytics = (payload) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ["get-bulkLink-single-analytics"],
    async () => {
      try {
        const response = await api.postWithToken(
          URL.GET_SINGLE_BUNCHES_ANALYTICS,
          payload
        );

        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      enabled: false,
    }
  );

  return { data, isLoading, isFetching, refetch, error };
};
