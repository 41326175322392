import React from "react";

function WrongImage() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.9974"
        cy="13.9998"
        r="12.9167"
        fill="white"
        stroke="#F64D4D"
        stroke-width="0.833319"
      />
      <circle cx="13.9954" cy="13.9998" r="10.3704" fill="#F74D4D" />
      <g clip-path="url(#clip0_2044_18448)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.77168 9.77241C9.63274 9.91139 9.55469 10.0999 9.55469 10.2964C9.55469 10.4929 9.63274 10.6814 9.77168 10.8204L17.1829 18.2316C17.3227 18.3666 17.5099 18.4413 17.7043 18.4396C17.8986 18.438 18.0845 18.36 18.2219 18.2226C18.3593 18.0852 18.4372 17.8993 18.4389 17.705C18.4406 17.5107 18.3659 17.3235 18.2309 17.1837L10.8196 9.77241C10.6807 9.63347 10.4922 9.55542 10.2957 9.55542C10.0991 9.55542 9.91066 9.63347 9.77168 9.77241Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.2298 9.77241C18.3687 9.91139 18.4468 10.0999 18.4468 10.2964C18.4468 10.4929 18.3687 10.6814 18.2298 10.8204L10.8185 18.2316C10.6787 18.3666 10.4915 18.4413 10.2972 18.4396C10.1029 18.438 9.91698 18.36 9.77957 18.2226C9.64216 18.0852 9.56422 17.8993 9.56253 17.705C9.56084 17.5107 9.63554 17.3235 9.77054 17.1837L17.1818 9.77241C17.3208 9.63347 17.5093 9.55542 17.7058 9.55542C17.9023 9.55542 18.0908 9.63347 18.2298 9.77241Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2044_18448">
          <rect
            width="8.88889"
            height="8.88889"
            fill="white"
            transform="translate(9.55469 9.55542)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WrongImage;
