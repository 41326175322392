import React, { Component } from "react";
import { STORAGEKEY } from "../Component/Constant";
import { userCheckToken } from "../services/hocService";
import { Box, CircularProgress } from "@mui/material";
import FoxlyLogoLoader from "../Component/logo/FoxlyLogoLoader";
// import UnderConstruction from "../Pages/ErrorPage/UnderConstruction";

const withAuth = (WrappedComponent) => {
  return class extends Component {
    state = {
      authenticated: false,
    };

    async componentDidMount() {
      // Check if user is authenticated
      const token = localStorage.getItem(STORAGEKEY.ACCESSTOKEN);
      const data = await userCheckToken({
        token: token ?? "Null",
      });
      this.setState({ authenticated: !data.data.error });
    }

    render() {
      const { authenticated } = this.state;

      if (authenticated) {
        return <WrappedComponent {...this.props} />;
      } else {
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            height={"100vh"}
            alignItems={"center"}
          >
            <FoxlyLogoLoader />
          </Box>
        );
      }
    }
  };
};

export default withAuth;
