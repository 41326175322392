import React from "react";

const Reseller_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M8.24967 1.83337C5.84801 1.83337 3.89551 3.78587 3.89551 6.18754C3.89551 8.54337 5.73801 10.45 8.13967 10.5325C8.21301 10.5234 8.28634 10.5234 8.34134 10.5325C8.35967 10.5325 8.36884 10.5325 8.38717 10.5325C8.39634 10.5325 8.39634 10.5325 8.40551 10.5325C10.7522 10.45 12.5947 8.54337 12.6038 6.18754C12.6038 3.78587 10.6513 1.83337 8.24967 1.83337Z"
          fill="#5B6B79"
        />
        <path
          d="M12.9069 12.9709C10.3494 11.2659 6.17854 11.2659 3.60271 12.9709C2.43854 13.7501 1.79688 14.8042 1.79688 15.9317C1.79688 17.0592 2.43854 18.1042 3.59354 18.8742C4.87688 19.7359 6.56354 20.1667 8.25021 20.1667C9.93688 20.1667 11.6235 19.7359 12.9069 18.8742C14.0619 18.0951 14.7035 17.0501 14.7035 15.9134C14.6944 14.7859 14.0619 13.7409 12.9069 12.9709Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M18.3246 6.72834C18.4712 8.50668 17.2062 10.065 15.4554 10.2758C15.4462 10.2758 15.4462 10.2758 15.4371 10.2758H15.4096C15.3546 10.2758 15.2996 10.2758 15.2537 10.2942C14.3646 10.34 13.5487 10.0558 12.9346 9.53334C13.8787 8.69001 14.4196 7.42501 14.3096 6.05001C14.2454 5.30751 13.9887 4.62918 13.6037 4.05168C13.9521 3.87751 14.3554 3.76751 14.7679 3.73084C16.5646 3.57501 18.1687 4.91334 18.3246 6.72834Z"
          fill="#5B6B79"
        />
        <path
          d="M20.1576 15.2075C20.0842 16.0967 19.5159 16.8667 18.5626 17.3892C17.6459 17.8934 16.4909 18.1317 15.3451 18.1042C16.0051 17.5084 16.3901 16.7659 16.4634 15.9775C16.5551 14.8409 16.0142 13.75 14.9326 12.8792C14.3184 12.3934 13.6034 12.0084 12.8242 11.7242C14.8501 11.1375 17.3984 11.5317 18.9659 12.7967C19.8092 13.475 20.2401 14.3275 20.1576 15.2075Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Reseller_Icon;
