import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentContent = ({ handleClose, handleDelete, content, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4">{content}</Typography>
      </Box>
      <Box
        sx={{ margin: "15px auto 5px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginX: "5px" }}
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          isLoading={isLoading}
        >
          {isLoading ? t("Loading") : t("BuyNow")}
        </Button>
      </Box>
    </>
  );
};

export default PaymentContent;
