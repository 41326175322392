import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  spaceDetailsName: "Space Details",
  planDetails: "PlanDetails",
  bulkLinkDetailsName: "Bulk Link Details",
};

const breadcrumbs = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.spaceDetailsName = action.payload;
    },
    setBulkLinkDetails: (state, action) => {
      state.bulkLinkDetailsName = action.payload;
    },
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
  },
});

export const { setBreadcrumbs, setPlanDetails, setBulkLinkDetails } =
  breadcrumbs.actions;
export default breadcrumbs.reducer;
