import { Alert, Snackbar, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { closeSnackbar } from "../store/reducers/snackbar";

const CustomSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const snackbarStore = useSelector((state) => state.snackbar);
  const { message, open: snackbarOpen, alert } = snackbarStore;

  useEffect(() => {
    setOpen(snackbarOpen);
  }, [snackbarOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
    setOpen(false);
  };

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
          key={"top_right"}
        >
          <Alert color={alert?.color} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CustomSnackbar;
