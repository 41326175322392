import React from "react";

const Links_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.8411 1.83337H7.1686C3.83194 1.83337 1.84277 3.82254 1.84277 7.15921V14.8317C1.84277 18.1684 3.83194 20.1575 7.1686 20.1575H14.8411C18.1778 20.1575 20.1669 18.1684 20.1669 14.8317V7.15921C20.1669 3.82254 18.1778 1.83337 14.8411 1.83337Z"
          fill="#5B6B79"
        />
        <path
          d="M11.183 15.9317C10.5413 15.9317 9.89046 15.6842 9.40462 15.1983C8.92796 14.7217 8.66212 14.0892 8.66212 13.42C8.66212 12.7509 8.92796 12.1092 9.40462 11.6417L10.6971 10.3492C10.9629 10.0834 11.4029 10.0834 11.6688 10.3492C11.9346 10.615 11.9346 11.055 11.6688 11.3209L10.3763 12.6134C10.1563 12.8334 10.0371 13.1175 10.0371 13.42C10.0371 13.7225 10.1563 14.0158 10.3763 14.2267C10.8255 14.6758 11.5496 14.6758 11.9988 14.2267L14.0338 12.1917C15.1979 11.0275 15.1979 9.13919 14.0338 7.97503C12.8696 6.81086 10.9813 6.81086 9.81713 7.97503L7.59876 10.1933C7.13126 10.6608 6.87461 11.275 6.87461 11.9259C6.87461 12.5767 7.13126 13.2 7.59876 13.6583C7.86459 13.9242 7.86459 14.3642 7.59876 14.63C7.33293 14.8958 6.89293 14.8958 6.6271 14.63C5.90293 13.9058 5.50879 12.9433 5.50879 11.9167C5.50879 10.89 5.90293 9.92752 6.6271 9.20336L8.84547 6.98501C10.5413 5.28918 13.3096 5.28918 15.0054 6.98501C16.7013 8.68084 16.7013 11.4492 15.0054 13.145L12.9705 15.18C12.4755 15.6842 11.8338 15.9317 11.183 15.9317Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Links_Icon;
