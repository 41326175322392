import React, { useEffect, useState } from "react";
import MainCard from "../../Component/MainCard";
import {
  useLinkClickChartData,
  useLinkUrlChartData,
} from "../../Hooks/Dashboard/useLinkUrlChartData";
import ReactApexChart from "react-apexcharts";
import { constants as CONSTS } from "../../Constants/constants";
import {
  Box,
  FormControl,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import DateRangePicker from "../../Component/DateRangePicker";
import CircleLoader from "../../Component/CircleLoader";
import { useTranslation } from "react-i18next";
import { ThemeMode } from "../../config";
import { makeStyles, useTheme } from "@mui/styles";

const LinksChart = ({
  LinkBioData = null,
  chartHeight = 450,
  url_shortner_id = "",
  is_rotator = false,
  is_bio_link = false,
  isBioLinkLoading = true,
  type,
  payload,
  setPayload,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectValue, setSelect] = useState("last7");
  const [labelName, setLabelName] = useState([]);
  const [rotatingList, setRotatingList] = useState([]);
  const [urlPayload, setUrlPayload] = useState({
    apiType: "POST",
    lastPath:
      url_shortner_id !== ""
        ? `/lastdays?url_shortner_id=${url_shortner_id || LinkBioData}`
        : "/lastdays",
    payload: { days: 7 },
    extra: "",
    url_shortner_id,
    is_bio_link,
  });
  const [selectDateRange, setSelectDateRange] = useState([null, null]);

  const useStyles = makeStyles((theme) => ({
    apexChartMenu: {
      "& .apexcharts-menu": {
        backgroundColor:
          theme.palette.mode === ThemeMode.DARK
            ? theme.palette.background.default
            : theme.palette.background.paper,
        color:
          theme.palette.mode === ThemeMode.DARK
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        boxShadow: theme.shadows[5],
        border: `1px solid ${theme.palette.divider}`,
      },
      "& .apexcharts-menu-item": {
        backgroundColor:
          theme.palette.mode === ThemeMode.DARK
            ? theme.palette.background.default
            : theme.palette.background.paper,
        color: theme.palette.text.primary,
        "&:hover": {
          backgroundColor: `${theme.palette.action.selected} !important`,
        },
      },
    },
  }));

  const classes = useStyles();

  const {
    data: urlData,
    refetch: urlDataRefetch,
    isFetching: urlFetching,
    isLoading: urlLoading,
  } = useLinkUrlChartData(urlPayload);

  const {
    data: clickData,
    refetch: clickDataRefetch,
    isFetching: clickFetching,
    isLoading: clickLoading,
  } = useLinkClickChartData(urlPayload);

  useEffect(() => {
    if (selectValue === "dateRange" && !!selectDateRange[1]) {
      setUrlPayload({
        apiType: "POST",
        lastPath:
          url_shortner_id !== ""
            ? `/dynamic_dates?url_shortner_id=${url_shortner_id}`
            : "/dynamic_dates",
        payload: {
          start_date: moment(selectDateRange[0]).format("yyyy-MM-DD"),
          end_date:
            moment(selectDateRange[1]).format("yyyy-MM-DD") ||
            moment(selectDateRange[0]).format("yyyy-MM-DD"),
        },
        extra: "",
        url_shortner_id,
        is_bio_link,
      });
    }
  }, [selectValue, selectDateRange, is_bio_link]);

  useEffect(() => {
    if (selectValue !== "dateRange") {
      setUrlPayload(getPayload(selectValue));
    }
  }, [selectValue]);

  useEffect(() => {
    if (type === "BulkLink") {
      setPayload({
        ...payload,
        clickquery: {
          url_shortner_id: urlPayload?.url_shortner_id,
          day:
            selectValue === "today" || selectValue === "yesterday"
              ? selectValue
              : "",
          month:
            selectValue === "thisMonth"
              ? "this"
              : selectValue === "lastMonth"
              ? "last"
              : "",
        },
        clicksBody: urlPayload?.payload,
      });
    }

    if (!!url_shortner_id) {
      clickDataRefetch();
    } else {
      clickDataRefetch();
      urlDataRefetch();
    }
  }, [urlPayload, url_shortner_id]);

  useEffect(() => {
    if (!!url_shortner_id) {
      if (is_rotator && clickData?.length > 0) {
        const arr = clickData.map((item) => {
          let obj = {
            name: `${
              item.name.length > 100
                ? `${item.name.slice(0, 70)}...`
                : item.name
            }`,
            data: Object.values(item.data),
          };
          return obj;
        });
        setRotatingList(arr);
        if (
          selectValue !== "yesterday" &&
          selectValue !== "today" &&
          !!clickData
        ) {
          setLabelName(Object.keys(clickData[0]?.data));
        } else {
          setLabelName(CONSTS.DAYS_LABLE);
        }
      } else {
        if (
          selectValue !== "yesterday" &&
          selectValue !== "today" &&
          !!clickData
        ) {
          setLabelName(Object.keys(clickData));
        } else {
          setLabelName(CONSTS.DAYS_LABLE);
        }
      }
    } else {
      if (selectValue !== "yesterday" && selectValue !== "today" && !!urlData) {
        setLabelName(Object.keys(urlData));
      } else {
        setLabelName(CONSTS.DAYS_LABLE);
      }
    }
  }, [urlData, clickData]);

  const getPayload = (name) => {
    switch (name) {
      case "last7":
        return {
          apiType: "POST",
          lastPath:
            url_shortner_id !== ""
              ? `/lastdays?url_shortner_id=${url_shortner_id}`
              : "/lastdays",
          payload: { days: 7 },
          extra: "",
          url_shortner_id,
          is_bio_link,
        };
        break;
      case "last30":
        return {
          apiType: "POST",
          lastPath:
            url_shortner_id !== ""
              ? `/lastdays?url_shortner_id=${url_shortner_id}`
              : "/lastdays",
          payload: { days: 30 },
          extra: "",
          url_shortner_id,
          is_bio_link,
        };
        break;
      case "yesterday":
        return {
          apiType: "GET",
          lastPath:
            url_shortner_id !== "" ? `?url_shortner_id=${url_shortner_id}` : "",
          payload: null,
          extra: "&day=yesterday",
          url_shortner_id,
          is_bio_link,
        };
        break;
      case "today":
        return {
          apiType: "GET",
          lastPath:
            url_shortner_id !== "" ? `?url_shortner_id=${url_shortner_id}` : "",
          payload: null,
          extra: "&day=today",
          url_shortner_id,
          is_bio_link,
        };
        break;
      case "thisMonth":
        return {
          apiType: "GET",
          lastPath:
            url_shortner_id !== ""
              ? `/month?url_shortner_id=${url_shortner_id}`
              : "/month",
          payload: null,
          extra: "&month=this",
          url_shortner_id,
          is_bio_link,
        };
        break;
      case "lastMonth":
        return {
          apiType: "GET",
          lastPath:
            url_shortner_id !== ""
              ? `/month?url_shortner_id=${url_shortner_id}`
              : "/month",
          payload: null,
          extra: "&month=last",
          url_shortner_id,
          is_bio_link,
        };
        break;
    }
  };
  const getName = (name) => {
    switch (name) {
      case "last7":
        return "Last7days";
        break;
      case "last30":
        return "Last30days";
        break;
      case "yesterday":
        return "Yesterday";
        break;
      case "today":
        return "Today";
        break;
      case "thisMonth":
        return "ThisMonth";
        break;
      case "lastMonth":
        return "LastMonth";
        break;
      case "dateRange":
        return `${moment(selectDateRange[0]).format("yyyy-MM-DD")} - ${moment(
          selectDateRange[1]
        ).format("yyyy-MM-DD")}`;
        break;
    }
  };

  const handleChangeSelect = (event) => {
    if (event.target.value !== "dateRange") {
      setSelectDateRange([null, null]);
      setUrlPayload(getPayload(event.target.value));
    }
    setSelect(event.target.value);
  };

  return (
    <MainCard content={false} style={{ overflow: "visible" }}>
      <Box>
        <Box sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 0, xsm: 1 }}
            sx={{ display: { xs: "grid", xsm: "flex" } }}
          >
            <Typography variant="h5">
              {selectValue === "dateRange"
                ? getName(selectValue)
                : t(getName(selectValue))}
            </Typography>
            <FormControl
              sx={{
                minWidth: 150,
                display: { xs: "block", xsm: "flex" },
                flexDirection: "row",
                alignItems: "center",
                marginLeft: { xs: 0, xsm: 2 },
              }}
            >
              <Select
                value={selectValue}
                onChange={handleChangeSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  marginTop: { xs: 1, xsm: 0 },
                  marginBottom: { xs: 1, xsm: 0 },
                }}
                onOpen={() => {
                  if (!selectDateRange[1] && !!selectDateRange[0]) {
                    setSelectDateRange([null, null]);
                    setSelect("last7");
                  }
                }}
                disabled={
                  clickLoading || clickFetching || urlLoading || urlFetching
                }
              >
                <MenuItem value={"today"}>
                  <em>{t("Today")}</em>
                </MenuItem>
                <MenuItem value={"yesterday"}>{t("Yesterday")}</MenuItem>
                <MenuItem value={"last7"}>{t("Last7days")}</MenuItem>
                <MenuItem value={"last30"}>{t("Last30days")}</MenuItem>
                <MenuItem value={"thisMonth"}>{t("ThisMonth")}</MenuItem>
                <MenuItem value={"lastMonth"}>{t("LastMonth")}</MenuItem>
                {selectValue === "dateRange" && (
                  <MenuItem value={"dateRange"}>
                    {`${moment(selectDateRange[0]).format(
                      "yyyy-MM-DD"
                    )} - ${moment(selectDateRange[1]).format("yyyy-MM-DD")}`}
                  </MenuItem>
                )}
              </Select>
              <Box
                sx={{
                  display: "flex",
                  marginLeft: { xs: 0, xsm: 1 },
                  marginBottom: { xs: 1, xsm: 0 },
                }}
              >
                <DateRangePicker
                  selectDateRange={selectDateRange}
                  setSelectDateRange={setSelectDateRange}
                  setSelect={setSelect}
                  disabled={
                    clickLoading || clickFetching || urlLoading || urlFetching
                  }
                />
              </Box>
            </FormControl>
          </Stack>
        </Box>
        {!!url_shortner_id ? (
          !clickFetching && !clickLoading && isBioLinkLoading ? (
            !!clickData ? (
              <Box className={classes.apexChartMenu}>
                <ReactApexChart
                  options={{
                    chart: {
                      height: { chartHeight },
                      type: "line",
                    },
                    dataLabels: { enabled: false },
                    stroke: { curve: "smooth", width: 3 },
                    xaxis: {
                      categories: labelName || CONSTS.DAYS_LABLE,
                      labels: {
                        style: {
                          fontSize: "11px",
                          colors:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.secondary.main
                              : theme.palette.secondary.darker,
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value);
                        },
                      },
                    },
                    tooltip: {
                      theme: theme.palette.mode,
                    },
                    legend: {
                      labels: {
                        colors:
                          theme.palette.mode === ThemeMode.DARK
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.darker,
                      },
                    },
                    series:
                      is_rotator === true
                        ? rotatingList
                        : [
                            {
                              name: t("Clicks"),
                              data: Object.values(clickData),
                            },
                          ],
                    colors: [
                      "#008ffb",
                      "#00e396",
                      "#feb019",
                      "#ff4560",
                      "#775dd0",
                      "#FF33FF",
                      "#FFFF33",
                      "#333333",
                      "#FF5733",
                      "#999999",
                    ],
                  }}
                  series={
                    is_rotator === true
                      ? rotatingList
                      : [{ name: t("Clicks"), data: Object.values(clickData) }]
                  }
                  type="area"
                  height={chartHeight} //450
                  className="apex-charts"
                />
              </Box>
            ) : (
              <>
                <ListItem>
                  <ListItemText
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: `${chartHeight - 10}px`,
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {t("NoDataAvailable")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </>
            )
          ) : (
            <CircleLoader mt="150px" height={`${chartHeight - 135}px`} />
          )
        ) : !urlFetching && !urlLoading && !clickFetching && !clickLoading ? (
          !!urlData ? (
            <Box className={classes.apexChartMenu}>
              <ReactApexChart
                options={{
                  chart: {
                    height: { chartHeight },
                    type: "line",
                  },
                  dataLabels: { enabled: false },
                  stroke: { curve: "smooth", width: 3 },
                  xaxis: {
                    categories: labelName || CONSTS.DAYS_LABLE,
                    labels: {
                      style: {
                        fontSize: "11px",
                        colors:
                          theme.palette.mode === ThemeMode.DARK
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.darker,
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      formatter: function (value) {
                        return Math.round(value);
                      },
                    },
                  },
                  series: [
                    { name: t("URLs"), data: Object.values(urlData) },
                    { name: t("Clicks"), data: Object.values(clickData) },
                  ],
                  tooltip: {
                    theme: theme.palette.mode,
                  },
                  legend: {
                    labels: {
                      colors:
                        theme.palette.mode === ThemeMode.DARK
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.darker,
                    },
                  },
                }}
                series={[
                  { name: t("URLs"), data: Object.values(urlData) },
                  { name: t("Clicks"), data: Object.values(clickData) },
                ]}
                type="area"
                height={chartHeight} //450
                className="apex-charts"
              />
            </Box>
          ) : (
            <>
              <ListItem>
                <ListItemText
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: `${chartHeight - 10}px`,
                  }}
                >
                  <Typography variant="caption" color="text.secondary">
                    {t("NoDataAvailable")}
                  </Typography>
                </ListItemText>
              </ListItem>
            </>
          )
        ) : (
          <CircleLoader mt="150px" height={`${chartHeight - 135}px`} />
        )}
      </Box>
    </MainCard>
  );
};

export default LinksChart;
