import { Box, Button, Stack, Typography } from "@mui/material";
import downgradeImg from "../../assets/links/DowngradePlan.png";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const DowngradeContent = ({
  handleDelete,
  content,
  isLoading,
  subDes,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <img src={downgradeImg} alt="qrcode" width={"245px"} />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" marginTop={2}>
          {content}
        </Typography>
        {subDes}
      </Box>
      <Box
        sx={{ margin: "15px auto 5px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button
          variant="contained"
          sx={{ marginX: "5px" }}
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          onClick={handleDelete}
          isLoading={isLoading}
        >
          {isLoading ? t("Loading") : t("DowngradePlan")}
        </LoadingButton>
      </Box>
    </>
  );
};

export default DowngradeContent;
