// third-party

// asset
import Dashboard_Icon from "../assets/navigation/Dashboard_Icon";
import FormattedMessageCustomize from "./FormattedMessageCusttomize";

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const dashboard = {
  id: "DASHBOARD",
  title: <FormattedMessageCustomize id="DashboardGroup" />,
  icon: Dashboard_Icon,
  type: "group",
  children: [
    {
      id: "Dashboard",
      title: <FormattedMessageCustomize id="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: Dashboard_Icon,
      index: 1,
    },
    {
      id: "Advance",
      title: <FormattedMessageCustomize id="ShortLinkAdvance" />,
      type: "subitem",
      url: "/dashboard/advance-link",
      icon: Dashboard_Icon,
      parents: {
        id: "Dashboard",
        title: <FormattedMessageCustomize id="Dashboard" />,
        url: "/dashboard",
      },
    },
  ],
};

export default dashboard;
