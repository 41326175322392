export const constants = {
  CLIENT_ID: "Client id:",
  TOTAL_BIO_LINK_USERS: "Total Bio Users",
  TODAYS_BIO_LINK_USERS: "Today's Bio Users",
  SECRET_ID: "Client secret:",
  CHANGE_IT: "Change it",
  DASHBOARD: "Web dashboard",
  LINK_BIO: "Link bio",
  ONLY_DASHBOARD: "Dashboard",
  SR_NO: "SR NO.",
  NAME: "Name",
  FULL_NAME: "Full name",
  EMAIL: "Email",
  TOTAL_URLS: "Total links",
  STATUS: "Status",
  DETAILS: "Details",
  SHORT_URL: "Short link",
  CLICKS: "Clicks",
  TAGS:"Tags",
  NOTE:"Note",
  CLICKS_SMALL: "clicks",
  SORRY: "Sorry!",
  NO_LINKS_FOUND: "No links found",
  NO_DATA_FOUND: "No data found",
  ALL: "All",
  VIEW_ALL: "View All",
  VIEW: "See more",
  TODAYS_URLS: "Today's links",
  TOTAL_CLICKS: "TotalClicks",
  TODAYS_CLICKS: "Today'sClicks",
  TODAYS_VISITORS: "Total Visitors",
  TOTAL_USERS: "Total users",
  USERS: "Users",
  TODAY_LOGIN_WITH_CHROME_EXTENSION: "Today's login with extension",
  DAU_PERCENTAGE: "Daily active users",
  MAU_PERCENTAGE: "Monthly active users",
  DAU_MAU_RATIO_PERCENTAGE: "DAU/MAU ratio",
  TODAY_URL_WITH_CHROME_EXTENSION: "Today's links with extension",
  TOTAL_URL_WITH_CHROME_EXTENSION: "Total links with extension",
  NEW_USERS: "New users",
  TOTAL_SPACES: "Total spaces",
  TOTAL_DOMAINS: "Total domains",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last7days",
  LAST_30_DAYS: "Last30days",
  THIS_MONTH: "ThisMonth",
  LAST_MONTH: "LastMonth",
  MOST_POPULAR: "Most popular",
  PROFILE: "Profile",
  UPDATE_PROFILE: "Update profile",
  SAVE: "Save",
  CANCEL: "Cancel",
  ADD_RESELLER: "Add reseller",
  RESELLER: "Reseller",
  ADD_SSL: "Add SSL certificate",
  SSL_CERT: "SSL certificate",
  BACK_TO_DEFAULT: "Default package",
  BACK_TO_DEFAULT_TIP: "Activate default package",
  SUB_USER: "Sub user",
  DEFAULT: "Default",
  COUPON: "Coupon",
  COUPON_CODE: "Coupon code",
  PACKAGE: "Package",
  DASH: "-",
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  EXPIRED: "Expired",
  UNVERIFIED: "Unverified",
  TEMP_ACTIVE: "Temp. Active",
  EDIT_STATUS: "Edit status",
  USER_DETAILS: "User details",
  PACKAGE_NAME: "Package name",
  PACKAGE_PRICE: "Package price",
  PRICE: "Price",
  RESELLER_LIMIT: "Reseller limit",
  SSL_LIMIT: "SSL certificate limit",
  REMAINING_RESELLER: "Remaining reseller",
  REMAINING_SSL: "Remaining SSL certificate",
  CREATED_AT: "CreatedAt",
  PURCHASE_AT: "Purchased at",
  ACTIVATED_AT: "Activated at",
  EXPIRE_ON: "Expire on",
  ACTION: "Action",
  USER_STATISTICS: "User Statistics",
  USERS_LIST: "Users list",
  PAID_USERS_LIST: "Paid user list",
  OVER_VIEW: "Overview",
  USER_DETAIL: "User detail",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  DELETE_IT: "Delete it!",
  YES: "Yes",
  SUB_USERS: "Sub Users",
  ADVERTISEMENT: "Show Ads",
  CHROME_EXTENSION_ADD_ON: "Chrome Extension Add-on",
  UNSUBSCRIBE_USER: "Unsubscribe User",
  TOTAL_LINKS: "Total links",
  LATEST_LINKS: "Latest links",
  QR: "QR",
  POPULAR_LINKS: "Popular links",
  LINKS: "Links",
  ALL_LINKS: "All Links",
  LINK: "Link",
  OG_LINK: "Original link",
  UTM: "UTM",
  TOP_BROWSER: "browser",
  TOP_REFERER: "referer",
  BROWSER: "Browser",
  TOP_OS: "operating system",
  OS: "operating system",
  TOP_COUNTRY: "country",
  COUNTRY: "Country",
  CUSTOME_DATE: "Custom date",
  ACTIVITY: "Activity",
  FILTERS: "Filters",
  ADVANCED: "Advanced",
  SHORTEN: "Shorten",
  DOMAIN: "Domain",
  ALIAS: "Alias",
  SPACE: "Space",
  DISABLED: "Disabled",
  BULK_LINKS: "Bulk links",
  BULK_NAME: "Bulk name",
  NO_BULK_FOUND: "No bulk found",
  PASSWORD: "Password",
  EXPIRATION_DATE: "Expiration date",
  EXPIRATION_LINK: "Expiration link",
  UTM_SOURCE: "UTM source (utm_source)",
  UTM_MEDIUM: "UTM medium (utm_medium)",
  UTM_CAMPAIGN: "UTM campaign (utm_campaign)",
  GENERATED_UTM_TRACKING_URL: "GENERATED UTM TRACKING URL",
  UTM_BUILDER: "UTM builder",
  TELEGRAM_BOT: "Telegram Bot",
  PIXEL_RETARGETING:"Pixel retargeting",
  SSL_CERTIFICATION: "SSL certificate",
  DAYS_LABLE: [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
  ],
  DELETE: "Delete",
  ADD_ON: "Foxly Chrome Extensions - Addon",
  PREVIOUS_PLANS: "Plan history",
  CURRENT_PACKAGE: "Current package",
  REMAINING_LINK: "Remaining links",
  SPACES: "Spaces",
  REMAINING_SPACES: "Remaining spaces",
  DOMAINS: "Domains",
  REMAINING_DOMAINS: "Remaining domains",
  MAXIMUM_RESELLER: "Maximum reseller",
  MAXIMUM_SSL: "Maximum SSL certificate",
  ADDITIONAL_DOMAIN: "Additional domain",
  ADVANCE_STATS: "Advanced stats",
  LINK_PASSWORD: "Link password",
  LINK_EXPIRATION: "Link expiration",
  LINK_DEACTIVATION: "Link deactivation",
  BULK_SHORTING: "Bulk shorting",
  DATA_EXPORT: "Data export",
  CHROME_EXTENSION: "Chrome extension add-on",
  AUTO_EXPIRATION_LINK: "Auto expiration link",
  AUTO_DELETION_LINK: "Auto deletion link",
  AUTO_EXPIRATION_NOTE:
    "Auto link expiration time will automatically add to shorten link based on the date of creation, and afterward all your existing and future links will be affected.",
  AUTO_DELETION_NOTE:
    "Auto deletion time will automatically add to shorten link based on the date of creation, And it will permanently delete your previous and future links.",

  CHANGE_STATUS: "Change status",
  ON_OFF: "ON/OFF",
  DURATION_TYPE: "Duration type",
  EXPIRATION_DURATION: "Expire duration",
  DELETION_DURATION: "Delete duration",
  EXPIRE: "Expire",
  COUPON_NAME: "Coupon name",
  REFERENCE_NAME: "Reference name",
  NUMBER_OF_COUPON: "Number of coupon",
  COUNTRY_NAME: "Country name",
  COUNTRY_CODE: "Country code",
  STATISTICS: "statistics",
  GENERATE_COUPON: "Generate coupon",
  REFERENCE_ERROR: "Reference name",
  NO_OF_COUPON: "Number of coupon",
  OTP: "OTP",
  VERIFICATION_CODE: "Verification code",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  SUPPORT_SETTING: "Support settings",
  DOMAIN_REDIRECT_SETTING: "Domain redirection settings",
  HELP_CENTER_LINK: "Help center link",
  SYSTEM_LINK: "System domain redirection link",
  USER_LINK: "User domain redirection link",
  TERMS_URL: "Terms link",
  SUPPORT_TEXT: "Landing page text",
  DOMAIN_SSL_TRAIN: "Domain SSL link",
  TELEGRAM_NOTIFICATION_SETTINGS: "Telegram notification settings",
  TIMEZONE: "Timezone",
  DAILY_NOTIFICATION_AT: "Send notification daily at",
  STEP1: `Step 1: Click on "Setup telegram bot" button.`,
  STEP2: "Step 2: Click on start button of your telegram.",
  STEP3:
    "Step 3: Now your own Folxy bot is ready, Just refresh your page after step 1 and 2.",
  SETUP_TELEGRAM_BOT: "Setup telegram bot",
  CHANGE_ACCOUNT: "Change account",
  DELETED_AT: "Deleted at",
  AFTER_LOGIN_TEXT: "System support text",
  PABBLY_USERNAME: "Pabbly username:",
  PABBLY_PASSWORD: "Pabbly password:",
  DOMAIN_IS_SSL: " My domain is only - http://",
  MY_DOMAIN_IS_HTTP: "My domain is only - http://",
  MY_DOMAIN_IS_SSL_CERT: "My domain has SSL certificate - https://",
  ENCRYPT_IS_SSL_CERT: "Encrypt SSL with Foxly",
  DELETED_LINKS: "Deleted links",
  DELETED_MALWARE_LINKS: "Deleted Malware links",
  DELETED_BY: "Deleted by",
  VERIFIED_DOMAINS: "Verified Domains",
  UNVERIFIED_DOMAINS: "Unverified Domains",
  USER_API_ADD_ON: "Foxly User API Add-on",
  API_KEY: "Key",
  TOTAL_GENERATE_COUNTS: "Re-generate Counts",
  TODAYS_URL:"Today's URLs",
  TOTAL_SHORT_URLS: "API Key URLs",
  ADD_ON_USER: "add-on",
  LAST_GEN_DATE: "Last Generated Date",
};
