import * as URL from "../../../api/url";
import api from "../../../api/api";
import { useQuery } from "react-query";

export const useGetStatusDataDeepLinking = ({
  apiType,
  lastPath,
  payload = null,
  extra = "",
}) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-status-click-data-deep-linking"],
    async () => {
      let baseUrl = apiType === "POST" ? api.postWithToken : api.getWithToken;

      try {
        const response = await baseUrl(
          `${URL.STATUS_DEEP_LINKING}${lastPath}${extra}`,
          payload
        );
        return {
          clickData:
            response.data.data.daysData ||
            response.data.data.hoursData ||
            response.data.data.thisMonthData ||
            response.data.data.lastMonthData,
        };
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useGetDeepLinkStateAnalytics = ({
  lastPath,
  auth,
  page,
  platform,
  extra = "",
  get,
  payload,
}) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    [`get-deep-link-analytics-data-${platform}`],
    async () => {
      const apiType = get ? api.getWithToken : api.postWithToken;
      const baseURL = `${URL.STATUS_DEEP_LINKING}${lastPath}&auth=${auth}&page=${page}&platform=${platform}${extra}`;
      try {
        const response = await apiType(baseURL, payload);
        return response?.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, isLoading, error, refetch, isFetching };
};
