import React from "react";

const Dashboard_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3_29777)">
          <path
            opacity="0.4"
            d="M9.54307 2.97528L9.54331 2.97509C10.3312 2.34327 11.6567 2.3418 12.4556 2.98389L12.4566 2.98471L18.8078 8.06195C18.808 8.06213 18.8082 8.06232 18.8085 8.0625C19.0753 8.27768 19.3212 8.63759 19.4826 9.06073C19.644 9.48378 19.701 9.91751 19.6455 10.2586C19.6455 10.2589 19.6454 10.2592 19.6454 10.2594L18.4268 17.5528C18.4267 17.5531 18.4267 17.5534 18.4266 17.5537C18.2469 18.6063 17.1989 19.4925 16.133 19.4925H5.86634C4.7906 19.4925 3.75229 18.6148 3.57275 17.5537C3.57273 17.5536 3.5727 17.5535 3.57268 17.5533L2.35367 10.2576L2.35368 10.2576L2.35315 10.2545C2.29426 9.91508 2.34881 9.48284 2.50985 9.06073C2.67087 8.63869 2.91838 8.27913 3.18913 8.06392L3.19056 8.06277L9.54307 2.97528Z"
            fill="#5B6B79"
            stroke="#5B6B79"
          />
          <path
            d="M12.7913 11.9167C12.7913 12.9062 11.9892 13.7083 10.9997 13.7083C10.0102 13.7083 9.20801 12.9062 9.20801 11.9167C9.20801 10.9272 10.0102 10.125 10.9997 10.125C11.9892 10.125 12.7913 10.9272 12.7913 11.9167Z"
            fill="#5B6B79"
            stroke="#5B6B79"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_29777">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Dashboard_Icon;
