import { useQuery, useMu, useMutation } from "react-query";
import api from "../../api/api";
import * as URL from "../../api/url";

export const useAccount = () => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-my-account"],
    async () => {
      try {
        const response = await api.getWithToken(URL.GET_USER_ACCOUNT);

        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useOnboardingUpdate = () => {
  const putRequest = async ({ payload }) => {
    try {
      const response = await api.putWithToken(
        URL.UPDATE_USER_ONBOARDING,
        payload
      );

      return new Date();
    } catch (error) {
      return error.response?.data;
    }
  };
  const { mutate, isLoading, error, data, isFetching } =
    useMutation(putRequest);

  return { data, error, mutate, isFetching, isLoading };
};
