import React from "react";

const DeepLinking = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M15.408 18.592c-.4 0-.73-.33-.73-.73 0-.4.33-.73.73-.73 2.82 0 5.12-2.3 5.12-5.12 0-2.82-2.3-5.12-5.12-5.12a5.13 5.13 0 0 0-5.12 5.12c0 .4-.33.73-.73.73-.4 0-.73-.33-.73-.73 0-3.63 2.95-6.59 6.59-6.59 3.64 0 6.58 2.95 6.58 6.58s-2.95 6.59-6.59 6.59Z"
          fill="#5B6B79"
        ></path>
        <path
          opacity=".4"
          d="M22 12a6.59 6.59 0 0 1-6.59 6.59c-1.25 0-2.42-.35-3.41-.96A6.57 6.57 0 0 0 15.17 12 6.57 6.57 0 0 0 12 6.37c1-.6 2.17-.96 3.41-.96A6.59 6.59 0 0 1 22 12Z"
          fill="#5B6B79"
        ></path>
        <path
          d="M8.59 5.41c.4 0 .73.33.73.73 0 .4-.33.73-.73.73a5.13 5.13 0 0 0-5.12 5.12c0 2.82 2.3 5.12 5.12 5.12 2.82 0 5.12-2.3 5.12-5.12 0-.4.33-.73.73-.73.4 0 .73.33.73.73 0 3.63-2.95 6.59-6.59 6.59C4.94 18.58 2 15.63 2 12s2.95-6.59 6.59-6.59Z"
          fill="#5B6B79"
        ></path>
        <path
          opacity=".5"
          d="M15.17 12A6.57 6.57 0 0 1 12 17.63c-1 .6-2.17.96-3.41.96A6.59 6.59 0 0 1 2 12a6.59 6.59 0 0 1 6.59-6.59c1.24 0 2.41.36 3.41.96A6.57 6.57 0 0 1 15.17 12Z"
          fill="#5B6B79"
        ></path>
      </svg>
    </>
  );
};

export default DeepLinking;
