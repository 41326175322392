import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeMode } from "../../config";

export const ApexDonutChart = ({ dynamicState }) => {
  const pieChartOptions = {
    chart: {
      type: "donut",
      height: 410,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: dynamicState?.labels || [],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const mode = theme.palette.mode;
  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const grey200 = theme.palette.secondary[200];
  const backColor = theme.palette.background.paper;
  const [series, setSeries] = useState(dynamicState?.series);
  const [options, setOptions] = useState(pieChartOptions);

  useEffect(() => {
    if (dynamicState?.series?.length > 0)
      setOptions((pre) => ({ ...pre, labels: dynamicState?.labels }));
    setSeries(dynamicState?.series);
  }, [dynamicState?.series]);

  useEffect(() => {
    const primaryMain = theme.palette.primary.main;
    const primaryLighter = theme.palette.primary[100];
    const warning = theme.palette.warning.main;
    const success = theme.palette.success.main;
    const secondary = theme.palette.secondary.dark;
    const error = theme.palette.error.main;

    setOptions((prevState) => ({
      ...prevState,
      colors: [
        primaryMain,
        warning,
        success,
        primaryLighter,
        "#7265E6",
        "#3EC9D6",
        "#B53874",
        secondary,
        error,
      ],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      fill: {
        type: "gradient",
      },
      grid: {
        borderColor: line,
      },
      stroke: {
        colors: [backColor],
        width: 0
      },
      theme: {
        mode: mode === ThemeMode.DARK ? "dark" : "light",
      },
    }));
  }, [mode, primary, line, grey200, backColor, theme]);

  return (
    <Box sx={{ overflow: "unset" }} id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={downSM ? 280 : 410}
      />
    </Box>
  );
};
