import PropTypes from "prop-types";
import { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ArrowDown2,
  ArrowUp2,
  Edit2,
  LanguageSquare,
  Logout,
  Messages3,
  SecuritySafe,
  Verify,
} from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfileTab = ({ handleLogout, handleClose, handleChangeLanguage }) => {
  const { i18n, t } = useTranslation();
  const navigation = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 4) {
      setCollapseOpen(!collapseOpen);
    }
  };

  const openWidget = (event) => {
    event.preventDefault();
    handleClose(event);
  };

  return (
    <List
      component="nav"
      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
    >
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={() => {
          navigation("/profile");
          setSelectedIndex(0);
        }}
      >
        <ListItemIcon>
          <Edit2 variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t("EditProfile")} />
      </ListItemButton>
      <a
        href="#"
        data-productlift-sidebar="1f0821c7-2a38-4d66-97d0-52cbd2cf3991"
        onClick={openWidget}
        style={{ textDecoration: "none" }}
      >
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <Verify variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary={t("Updates")} />
        </ListItemButton>
      </a>
      <a
        href="#"
        data-productlift-sidebar="3eca4e17-afe3-4f50-847d-4acdd7afcf9f"
        onClick={openWidget}
        style={{ textDecoration: "none" }}
      >
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleListItemClick(event, 2);
            handleClose(event);
          }}
        >
          <ListItemIcon>
            <Messages3 variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary={t("FeatureRequest")} />
        </ListItemButton>
      </a>
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event) => {
          navigation("/security");
          setSelectedIndex(3);
        }}
      >
        <ListItemIcon>
          <SecuritySafe variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t("Security")} />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) => handleListItemClick(event, 4)}
      >
        <ListItemIcon>
          <LanguageSquare size={18} variant="Bulk" />
        </ListItemIcon>
        <ListItemText primary={t("ChangeLanguage")} />
        {collapseOpen ? (
          <ArrowDown2 style={{ fontSize: "0.5rem" }} size={18} />
        ) : (
          <ArrowUp2 style={{ fontSize: "0.5rem" }} size={18} />
        )}
      </ListItemButton>
      <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ bgcolor: "secondary.100" }}>
          <ListItemButton
            sx={{ pl: 5 }}
            selected={i18n.language === "en-US"}
            onClick={(event) => {
              handleChangeLanguage("en-US");
              openWidget(event);
            }}
          >
            <ListItemText primary="English (US)" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 5 }}
            selected={i18n.language === "es"}
            onClick={(event) => {
              handleChangeLanguage("es");
              openWidget(event);
            }}
          >
            <ListItemText primary="Spanish" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton selected={selectedIndex === 5} onClick={handleLogout}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t("Logout")} />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
