// import { KEYCLOAK_CONFIG } from "../../app-config";
import * as url from "../../api/url";
import api from "../../api/api";
import { useMutation, useQuery } from "react-query";
import { notificationToast } from "../../Helpers/common";
import { notificationMessageFunction } from "../../Constants/notificationConst";
import { useTranslation } from "react-i18next";

export const useAddBulk = () => {
  const { t } = useTranslation();
  const postRequest = async ({ domain_id, payload }) => {
    try {
      const response = await api.postWithToken(
        `${url.BUNCH_URLS}?domain_id=${domain_id}`,
        payload
      );

      return response.data;
    } catch (error) {
      notificationToast(
        t(notificationMessageFunction(error?.response?.data?.error?.message)),
        "error"
      );
    }
  };
  const { mutate, isLoading, isFetching, error, data } =
    useMutation(postRequest);

  return { mutate, isLoading, isFetching, error, data };
};

export const useEditBulk = () => {
  const { t } = useTranslation();
  const postRequest = async ({ id, domain_id, payload }) => {
    try {
      const response = await api.putWithToken(
        `${url.BUNCHS_ALL_URLS}?bunch_id=${id}${
          domain_id ? `&domain_id=${domain_id}` : ""
        }`,
        payload
      );
      return response.data;
    } catch (error) {
      notificationToast(
       t(notificationMessageFunction(error?.response?.data?.error?.message)),
        "error"
      );
    }
  };

  const { mutate, isLoading, error, data } = useMutation(postRequest);

  return { mutate, isLoading, error, data };
};

export const GetBulkIdData = (bunch_id) => {
  const { t } = useTranslation();
  const { data, error, refetch, isFetching } = useQuery(
    ["get-id-bulk-data-edit"],
    async () => {
      try {
        const response = await api.getWithToken(
          url.BUNCHS_DETAILS + `?bunch_id=${bunch_id}`
        );
        return response.data?.data?.data;
      } catch (error) {
        notificationToast(
          t(notificationMessageFunction(error?.response?.data?.error?.message)),
          "error"
        );
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isFetching };
};
