import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDynamicCountryData } from "../../Hooks/Dashboard/useDynamicCountryData";
import { getBrowserIcon, getOsIcon } from "../../Helpers/dashboard";
import countryData from "../../Utils/country.json";
import CircleLoader from "../../Component/CircleLoader";
import EmptyScreen from "../../Component/EmptyScreen";
import { useGetSpaceStateAnalytics } from "../../Hooks/Spaces/useGetSpaceAnalytics";
import { useGetDeepLinkStateAnalytics } from "../../Hooks/DeepLinking/States/useGetStatusDataDeepLinking";
import { TabPanel } from "../../Helpers/common";
import { useTranslation } from "react-i18next";

const RightSide = ({ item }) => {
  const { t } = useTranslation();
  
  return (
    <Stack spacing={0.25} alignItems="flex-end">
      <Typography variant="subtitle1">{item?.count || 0}</Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        {t("Clicks")}
      </Typography>
    </Stack>
  );
};

const LeftSide = ({ icon, title }) => {
  return (
    <ListItemText
      primary={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!!icon && (
            <Box
              sx={{
                width: "40px",
                height: "40px",
                objectFit: "fill",
                borderRadius: "5px",
              }}
            >
              {icon}
            </Box>
          )}
          <Typography variant="subtitle1" sx={{ marginLeft: "1em" }}>
            {title || ""}
          </Typography>
        </Box>
      }
      sx={{ maxWidth: "90%" }}
    />
  );
};

const Empty = ({ msg }) => {
  return (
    <ListItem>
      <ListItemText
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "270px",
        }}
      >
        <EmptyScreen msg={msg} />
      </ListItemText>
    </ListItem>
  );
};

const AdvancedAnalysis = ({ value, filterValue, advanceId = "" }) => {
  const { t } = useTranslation();
  const [analysisData, setAnalysisData] = useState([]);
  const {
    data: dynamicState,
    refetch,
    isLoading,
    isFetching,
  } = useDynamicCountryData(filterValue);
  const {
    refetch: SpaceStateDataRefetch,
    data: SpaceStateData,
    isLoading: SpaceStateDataIsLoading,
    isFetching: SpaceStateDataIsFetching,
  } = useGetSpaceStateAnalytics(filterValue);
  const {
    refetch: DeepLinkStateDataRefetch,
    data: DeepLinkStateData,
    isLoading: DeepLinkStateDataIsLoading,
    isFetching: DeepLinkStateDataIsFetching,
  } = useGetDeepLinkStateAnalytics(filterValue);

  useEffect(() => {
    if (advanceId === "advanceAnalysis") {
      setAnalysisData(dynamicState || []);
    } else if (advanceId === "spaceAnalytics") {
      setAnalysisData(SpaceStateData || []);
    } else if (advanceId === "advanceAnalysisDeepLink") {
      setAnalysisData(DeepLinkStateData || []);
    }
  }, [dynamicState, SpaceStateData, DeepLinkStateData]);

  useEffect(() => {
    if (advanceId === "advanceAnalysis") {
      refetch();
    } else if (advanceId === "spaceAnalytics") {
      SpaceStateDataRefetch();
    } else if (advanceId === "advanceAnalysisDeepLink") {
      DeepLinkStateDataRefetch();
    }
  }, [filterValue, value]);

  return (
    <>
      {isLoading ||
      isFetching ||
      SpaceStateDataIsFetching ||
      SpaceStateDataIsLoading ||
      DeepLinkStateDataIsLoading ||
      DeepLinkStateDataIsFetching ? (
        <CircleLoader />
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <List
              disablePadding
              sx={{ "& .MuiListItem-root": { px: 3, py: 1.5 } }}
            >
              {analysisData?.length > 0 ? (
                analysisData?.map((item, index) => {
                  return (
                    <ListItem
                      divider
                      secondaryAction={<RightSide item={item} />}
                    >
                      <LeftSide
                        icon={getOsIcon(item?.os)}
                        title={item?.os || ""}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Empty msg={t("NoClicksFound")} />
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <List
              disablePadding
              sx={{ "& .MuiListItem-root": { px: 3, py: 1.5 } }}
            >
              {analysisData?.length > 0 ? (
                analysisData?.map((item, index) => {
                  return (
                    <ListItem
                      divider
                      secondaryAction={<RightSide item={item} />}
                    >
                      <LeftSide
                        icon={
                          <ReactCountryFlag
                            countryCode={item?.country || ""}
                            svg
                            style={{
                              width: "3em",
                              height: "3em",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            title=""
                          />
                        }
                        title={
                          countryData.find((i) => i?.Code === item?.country)
                            ?.Name ||
                          item?.country ||
                          ""
                        }
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Empty msg={t("NoClicksFound")} />
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <List
              disablePadding
              sx={{ "& .MuiListItem-root": { px: 3, py: 1.5 } }}
            >
              {analysisData?.length > 0 ? (
                analysisData?.map((item, index) => {
                  return (
                    <ListItem
                      divider
                      secondaryAction={<RightSide item={item} />}
                    >
                      <LeftSide
                        icon={getBrowserIcon(item?.browser)}
                        title={item?.browser}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Empty msg={t("NoClicksFound")} />
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <List
              disablePadding
              sx={{ "& .MuiListItem-root": { px: 3, py: 1.5 } }}
            >
              {analysisData?.length > 0 ? (
                analysisData?.map((item, index) => {
                  return (
                    <ListItem
                      divider
                      secondaryAction={<RightSide item={item} />}
                    >
                      <LeftSide
                        icon={
                          <ReactCountryFlag
                            countryCode={item?.country || ""}
                            svg
                            style={{
                              width: "3em",
                              height: "3em",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            title={` ${item?.city || "-"}${","}
                            ${
                              countryData.find((i) => i?.Code === item?.country)
                                ?.Name ||
                              item?.country ||
                              ""
                            }`}
                          />
                        }
                        title={` ${item?.city || ""}${item?.city ? "," : ""}
                            ${
                              countryData.find((i) => i?.Code === item?.country)
                                ?.Name ||
                              item?.country ||
                              ""
                            }`}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Empty msg={t("NoClicksFound")} />
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <List
              disablePadding
              sx={{ "& .MuiListItem-root": { px: 3, py: 1.5 } }}
            >
              {analysisData?.length > 0 ? (
                analysisData?.map((item, index) => {
                  return (
                    <ListItem
                      divider
                      secondaryAction={<RightSide item={item} />}
                    >
                      <LeftSide icon={null} title={item?.referer} />
                    </ListItem>
                  );
                })
              ) : (
                <Empty msg={t("NoClicksFound")} />
              )}
            </List>
          </TabPanel>
        </>
      )}
    </>
  );
};

export default AdvancedAnalysis;
