// ==============================|| OVERRIDES - TOOLTIP ||============================== //

import { ThemeMode } from "../../config";

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.secondary.main
              : theme.palette.background.paper,
        },
      },
    },
  };
}
