import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project-imports
import Palette from "./palette";
import Typography from "./typography";
import CustomShadows from "./shadows";
import componentsOverride from "./overrides";
import { HEADER_HEIGHT, ThemeMode } from "../config";
import getWindowScheme from "../Utils/getWindowScheme";
import useConfig from "../Hooks/useConfig";

// import { HEADER_HEIGHT } from 'config';
// import useConfig from 'hooks/useConfig';
// import getWindowScheme from 'utils/getWindowScheme';
// import { ThemeMode } from 'config';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
  const { themeDirection, mode, presetColor, fontFamily, themeContrast } =
    useConfig();
  let themeMode = mode;
  if (themeMode === ThemeMode.AUTO) {
    const autoMode = getWindowScheme();
    if (autoMode) {
      themeMode = ThemeMode.DARK;
    } else {
      themeMode = ThemeMode.LIGHT;
    }
  }

  const theme = useMemo(
    () => Palette(themeMode, presetColor, themeContrast),
    [themeMode, presetColor, themeContrast]
  );

  const themeTypography = useMemo(
    () => Typography(fontFamily),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fontFamily]
  );
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          xsm:350,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
          xxl: 1740,
          xxxl: 3000,
        },
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: HEADER_HEIGHT,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      shape: {
        borderRadius: 8,
      },
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes} disableTransitionOnChange>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
