import { lazy } from "react";
import AuthGuard from "../Utils/route-guard/AuthGuard";
import Loadable from "../Component/Loadable";
import MainLayout from "../Layouts/MainLayout";
import CommonLayout from "../Layouts/CommonLayout";
import CommonLayoutWithAuth from "../Layouts/CommonLayoutWithAuth";
import Stats from "../Pages/Links/Stats";
import Error404 from "../Pages/ErrorPage/404";
import BulkLinksForm from "../Pages/BulkLinks/BulkLinksForm";
import PlanDetails from "../Pages/Plan/PlanDetail";
import LifeTime from "../Pages/Plan/PlanDetail/LifeTime";
import DomainSuccess from "../Pages/Domains/DomainSuccess";

//Protected
const DashboardDefault = Loadable(lazy(() => import("../Pages/Dashboard")));
const ProfileDefault = Loadable(lazy(() => import("../Pages/Profile")));
const LinksDefault = Loadable(lazy(() => import("../Pages/Links")));
const DeepLinking = Loadable(lazy(() => import("../Pages/DeepLinking")));
const DeepLinkingAdd = Loadable(
  lazy(() => import("../Pages/DeepLinking/DeepLinking"))
);
const DeepLinkStats = Loadable(
  lazy(() => import("../Pages/DeepLinking/DeepLinkStats"))
);
const LinkCloaking = Loadable(lazy(() => import("../Pages/LinkCloaking")));
const CustomQRCode = Loadable(lazy(() => import("../Pages/CustomQRCode")));
const CustomQRCodeAdd = Loadable(
  lazy(() => import("../Pages/CustomQRCode/CustomQRCodeAdd"))
);
const CustomQRCodeStats = Loadable(
  lazy(() => import("../Pages/CustomQRCode/Stats/index"))
);
const AdvanceLink = Loadable(lazy(() => import("../Pages/AdvanceLinks")));
const BulkLinksDefault = Loadable(lazy(() => import("../Pages/BulkLinks")));
const BulkLinkDetailsDefault = Loadable(
  lazy(() => import("../Pages/BulkLinks/BulkLinkList"))
);
const BioLinkDetailsDefault = Loadable(
  lazy(() => import("../Pages/LinksBio/Details/index"))
);
const BioLinkStateDefault = Loadable(
  lazy(() => import("../Pages/LinksBio/Stats/index"))
);
const BioLinkIconStateDefault = Loadable(
  lazy(() => import("../Pages/LinksBio/Details/URL_Icons/Stats/index"))
);
const LinksBioDefault = Loadable(lazy(() => import("../Pages/LinksBio")));
const SpacesDefault = Loadable(lazy(() => import("../Pages/Spaces")));
const DomainsDefault = Loadable(lazy(() => import("../Pages/Domains")));
const DomainsCreateDefault = Loadable(
  lazy(() => import("../Pages/Domains/DomainCreate"))
);
const DownloadFiles = Loadable(lazy(() => import("../Pages/DownloadFiles")));
const FileDetail = Loadable(
  lazy(() => import("../Pages/DownloadFiles/fileDetail"))
);
const FileUrlsStats = Loadable(
  lazy(() => import("../Pages/DownloadFiles/Stats/index"))
);
const ImportLink = Loadable(lazy(() => import("../Pages/ImportLink")));
const ImportLinkDetail = Loadable(
  lazy(() => import("../Pages/ImportLink/ImportLinkDetails"))
);
const FavouritesLinks = Loadable(
  lazy(() => import("../Pages/FavouritesLinks"))
);
const SpaceAnalyticsDefault = Loadable(
  lazy(() => import("../Pages/Spaces/SpaceAnalytics"))
);
const SpaceDetailDefault = Loadable(lazy(() => import("../Pages/SpaceDetail")));
const SpaceLinkStats = Loadable(lazy(() => import("../Pages/Stats")));
const ResellerDefault = Loadable(lazy(() => import("../Pages/Reseller")));
const ResellerFeatures = Loadable(
  lazy(() => import("../Pages/Reseller/ResellerFeatures"))
);
const Setting = Loadable(lazy(() => import("../Pages/Setting")));
const BookmarkBTN = Loadable(lazy(() => import("../Pages/BookmarkBTN")));
const CouponOrLicenseKey = Loadable(
  lazy(() => import("../Pages/CouponOrLicenseKey"))
);
const AllLinks = Loadable(lazy(() => import("../Pages/AllLinks")));
const DailyAnalytics = Loadable(
  lazy(() => import("../Pages/DailyAnalytics/index"))
);
const DeletedLinks = Loadable(lazy(() => import("../Pages/DeletedLinks")));
const Security = Loadable(lazy(() => import("../Pages/Security/index")));
const HelpCenter = Loadable(lazy(() => import("../Pages/HelpCenter")));

// pages routing
const AuthLogin = Loadable(lazy(() => import("../Pages/Login")));
const AuthRegister = Loadable(lazy(() => import("../Pages/Register")));
const AuthResetPassword = Loadable(
  lazy(() => import("../Pages/ForgotPassword/ResetPassword"))
);
const AuthVerifyAccount = Loadable(
  lazy(() => import("../Pages/Register/AuthVerifyAccount"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("../Pages/ForgotPassword"))
);

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  element: <CommonLayout />,
  children: [
    {
      path: "/",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "*",
          element: (
            <>
              <Error404 path="/dashboard" />
            </>
          ),
        },
        {
          path: "dashboard",
          element: (
            <>
              <DashboardDefault />
            </>
          ),
        },
        {
          path: "links",
          element: (
            <>
              <LinksDefault />
            </>
          ),
        },
        {
          path: "deepLinking",
          element: (
            <>
              <DeepLinking />
            </>
          ),
        },
        {
          path: "linkCloaking",
          element: <LinkCloaking />,
        },
        {
          path: "bulkLinks",
          element: (
            <>
              <BulkLinksDefault />
            </>
          ),
        },
        {
          path: "linkBio",
          element: (
            <>
              <LinksBioDefault />
            </>
          ),
        },
        {
          path: "spaces",
          element: (
            <>
              <SpacesDefault />
            </>
          ),
        },
        {
          path: "domains",
          element: (
            <>
              <DomainsDefault />
            </>
          ),
        },
        {
          path: "qrCode",
          element: (
            <>
              <CustomQRCode />
            </>
          ),
        },
        {
          path: "files",
          element: <DownloadFiles />,
        },
        {
          path: "importLink",
          element: <ImportLink />,
        },
        {
          path: "favouriteLinks",
          element: <FavouritesLinks />,
        },
        {
          path: "allLinks",
          element: (
            <>
              <AllLinks />
            </>
          ),
        },
        {
          path: "dailyAnalytics",
          element: (
            <>
              <DailyAnalytics />
            </>
          ),
        },
        {
          path: "reseller",
          element: (
            <>
              <ResellerDefault />
            </>
          ),
        },
        {
          path: "deletedLinks",
          element: (
            <>
              <DeletedLinks />
            </>
          ),
        },
        {
          path: "Profile",
          element: (
            <>
              <ProfileDefault />
            </>
          ),
        },
        {
          path: "coupon",
          element: (
            <>
              <CouponOrLicenseKey />
            </>
          ),
        },
        {
          path: "aboutPlans",
          element: <></>,
        },
        {
          path: "settings",
          element: <Setting />,
        },
        {
          path: "bookmark",
          element: <BookmarkBTN />,
        },
        {
          path: "plans",
          element: <></>,
        },
        {
          path: "security",
          element: (
            <>
              <Security />
            </>
          ),
        },
        {
          path: "helpCenter",
          element: (
            <>
              <HelpCenter />
            </>
          ),
        },
      ],
    },
    {
      path: "domain-success",
      element: (
        <>
          <DomainSuccess />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "advance-link",
          element: (
            <>
              <AdvanceLink />
            </>
          ),
        },
      ],
    },
    {
      path: "/links",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "advance-link",
          element: (
            <>
              <AdvanceLink link_cloaking={false} />
            </>
          ),
        },
        {
          path: "edit-link",
          element: (
            <>
              <AdvanceLink link_cloaking={false} />
            </>
          ),
        },
        {
          path: "stats",
          element: (
            <>
              <Stats />
            </>
          ),
        },
      ],
    },
    {
      path: "/deepLinking",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "addNewDeepLinking",
          element: (
            <>
              <DeepLinkingAdd />
            </>
          ),
        },
        {
          path: "editDeepLinking",
          element: (
            <>
              <DeepLinkingAdd />
            </>
          ),
        },
        {
          path: "stats",
          element: (
            <>
              <DeepLinkStats />
            </>
          ),
        },
      ],
    },
    {
      path: "/linkCloaking",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "addNewLinkCloaking",
          element: (
            <>
              <AdvanceLink link_cloaking={true} />
            </>
          ),
        },
        {
          path: "editLinkCloaking",
          element: (
            <>
              <AdvanceLink link_cloaking={true} />
            </>
          ),
        },
        {
          path: "stats",
          element: (
            <>
              <Stats />
            </>
          ),
        },
      ],
    },
    {
      path: "/qrCode",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "addQRCode",
          element: (
            <>
              <CustomQRCodeAdd />
            </>
          ),
        },
        {
          path: "editQRCode",
          element: (
            <>
              <CustomQRCodeAdd />
            </>
          ),
        },
        {
          path: "QRCodeStats",
          element: (
            <>
              <CustomQRCodeStats />
            </>
          ),
        },
      ],
    },
    {
      path: "/files",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "fileDetail",
          element: <FileDetail />,
        },
        {
          path: "fileUrlsStats",
          element: (
            <>
              <FileUrlsStats />
            </>
          ),
        },
      ],
    },
    {
      path: "/importLink",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "importLinkDetail",
          element: (
            <>
              <ImportLinkDetail />
            </>
          ),
        },
      ],
    },
    {
      path: "/allLinks",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "stats",
          element: (
            <>
              <Stats />
            </>
          ),
        },
        {
          path: "edit-link",
          element: (
            <>
              <AdvanceLink />
            </>
          ),
        },
      ],
    },
    {
      path: "/dailyAnalytics",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "stats",
          element: (
            <>
              <Stats />
            </>
          ),
        },
      ],
    },
    {
      path: "/bulkLinks",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "bulkDetails",
          element: (
            <>
              <BulkLinkDetailsDefault />
            </>
          ),
        },
        {
          path: "analytics",
          element: (
            <>
              <SpaceAnalyticsDefault type={"bulkLinks"} />
            </>
          ),
        },
        {
          path: "bulk-stats",
          element: (
            <>
              <Stats type={"BulkLink"} />
            </>
          ),
        },
        {
          path: "bulkEdit",
          element: (
            <>
              <BulkLinksForm edit={true} />
            </>
          ),
        },
      ],
    },
    {
      path: "/linkBio",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "bioLinkDetails",
          element: (
            <>
              <BioLinkDetailsDefault />
            </>
          ),
        },
        {
          path: "bioLinkStats",
          element: (
            <>
              <BioLinkStateDefault />
            </>
          ),
        },
        {
          path: "bioLinkIconStats",
          element: (
            <>
              <BioLinkIconStateDefault />
            </>
          ),
        },
      ],
    },
    {
      path: "/domains",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "add",
          element: (
            <>
              <DomainsCreateDefault />
            </>
          ),
        },
        {
          path: "update",
          element: (
            <>
              <DomainsCreateDefault />
            </>
          ),
        },
        {
          path: "stats",
          element: (
            <>
              <Stats />
            </>
          ),
        },
      ],
    },
    {
      path: "/spaces",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "analytics",
          element: (
            <>
              <SpaceAnalyticsDefault type={"spaces"} />
            </>
          ),
        },
        {
          path: "detail",
          element: (
            <>
              <SpaceDetailDefault />
            </>
          ),
        },
        {
          path: "stats",
          element: (
            <>
              <SpaceLinkStats />
            </>
          ),
        },
      ],
    },
    {
      path: "/reseller",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "resellerfeatures",
          element: (
            <>
              <ResellerFeatures />
            </>
          ),
        },
      ],
    },
    {
      path: "/plans",
      element: <CommonLayoutWithAuth layout="landing" />,
      children: [
        {
          path: "details",
          element: <PlanDetails mode="Monthly" />,
        },
        {
          path: "subuser",
          element: <PlanDetails mode="None" />,
        },
        {
          path: "detailsLifetime",
          element: <PlanDetails mode="Lifetime" />,
        },
        {
          path: "lifeTimePlan",
          element: <LifeTime mode="Lifetime" />,
        },
      ],
    },
    {
      path: "/auth",
      element: <CommonLayout />,
      children: [
        {
          path: "*",
          element: (
            <>
              <Error404 path="/auth/login" />
            </>
          ),
        },
        {
          path: "login",
          element: <AuthLogin />,
        },
        {
          path: "register",
          element: <AuthRegister />,
        },
        {
          path: "verify",
          element: <AuthVerifyAccount />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
      ],
    },
  ],
};

export default MainRoutes;
